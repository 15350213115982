/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PerformerSpecialty } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class PerformerSpecialties<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags performer-specialties
   * @name PerformerSpecialtiesList
   * @request GET:/performer-specialties/
   * @secure
   */
  performerSpecialtiesList = (params: RequestParams = {}) =>
    this.request<PerformerSpecialty[], any>({
      path: `/performer-specialties/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags performer-specialties
   * @name PerformerSpecialtiesCreate
   * @request POST:/performer-specialties/
   * @secure
   */
  performerSpecialtiesCreate = (data: PerformerSpecialty, params: RequestParams = {}) =>
    this.request<PerformerSpecialty, any>({
      path: `/performer-specialties/`,
      method: "POST",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags performer-specialties
   * @name PerformerSpecialtiesRead
   * @request GET:/performer-specialties/{id}/
   * @secure
   */
  performerSpecialtiesRead = (id: string, params: RequestParams = {}) =>
    this.request<PerformerSpecialty, any>({
      path: `/performer-specialties/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags performer-specialties
   * @name PerformerSpecialtiesUpdate
   * @request PUT:/performer-specialties/{id}/
   * @secure
   */
  performerSpecialtiesUpdate = (id: string, data: PerformerSpecialty, params: RequestParams = {}) =>
    this.request<PerformerSpecialty, any>({
      path: `/performer-specialties/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags performer-specialties
   * @name PerformerSpecialtiesPartialUpdate
   * @request PATCH:/performer-specialties/{id}/
   * @secure
   */
  performerSpecialtiesPartialUpdate = (id: string, data: PerformerSpecialty, params: RequestParams = {}) =>
    this.request<PerformerSpecialty, any>({
      path: `/performer-specialties/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
}
