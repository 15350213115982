/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { EventLocation, GetLocationForAddyRequest, GetLocationForAddyResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Locations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags locations
   * @name LocationsList
   * @request GET:/locations/
   * @secure
   */
  locationsList = (params: RequestParams = {}) =>
    this.request<EventLocation[], any>({
      path: `/locations/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags locations
   * @name LocationsCreate
   * @request POST:/locations/
   * @secure
   */
  locationsCreate = (data: EventLocation, params: RequestParams = {}) =>
    this.request<EventLocation, any>({
      path: `/locations/`,
      method: "POST",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Find location obj for address string
   *
   * @tags locations
   * @name GetLocationForAddy
   * @request POST:/locations/get_location_for_addy/
   * @secure
   */
  getLocationForAddy = (data: GetLocationForAddyRequest, params: RequestParams = {}) =>
    this.request<GetLocationForAddyResponse, any>({
      path: `/locations/get_location_for_addy/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags locations
   * @name LocationsRead
   * @request GET:/locations/{id}/
   * @secure
   */
  locationsRead = (id: string, params: RequestParams = {}) =>
    this.request<EventLocation, any>({
      path: `/locations/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags locations
   * @name LocationsUpdate
   * @request PUT:/locations/{id}/
   * @secure
   */
  locationsUpdate = (id: string, data: EventLocation, params: RequestParams = {}) =>
    this.request<EventLocation, any>({
      path: `/locations/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags locations
   * @name LocationsPartialUpdate
   * @request PATCH:/locations/{id}/
   * @secure
   */
  locationsPartialUpdate = (id: string, data: EventLocation, params: RequestParams = {}) =>
    this.request<EventLocation, any>({
      path: `/locations/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
}
