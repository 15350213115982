import {
  AutoFlowPartyRequest,
  EventLocation,
} from "../../apiClient/data-contracts";
import { AutoEventCreateForm } from "./requestTypes";

export const convertZodAutoToApiAuto = (
  values: AutoEventCreateForm,
  specificLocation: EventLocation | undefined
): AutoFlowPartyRequest => {
  const budget = values.budget;
  const expected_attending = values.attendees;
  const specific_location = specificLocation?.id;
  const rough_location = values.location.rough;
  const party_date = values.eventTime.toISOString();
  const description = values.description;
  let performer_specs = undefined;

  if (
    values.performersOptions?.performerType &&
    values.performersOptions?.specialty
  ) {
    performer_specs = {
      type: values.performersOptions.performerType,
      specialties: values.performersOptions.specialty,
    };
  }

  return {
    budget,
    party_date,
    expected_attending,
    specific_location,
    rough_location,
    performer_specs,
    description,
  };
};
