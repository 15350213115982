import { Stack, TextField, Typography } from "@mui/material";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import React from "react";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { StringParam, useQueryParam } from "use-query-params";
import { MonkeyButton } from "../components/Button";
import { eventMonkeyApi, useLogUserInMutation } from "../endpoints";
import { useIsSmallScreen } from "../logic/hooks";

export const SignIn = (): React.ReactElement => {
  // state
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [password, setPassword] = React.useState<any | undefined>(undefined);

  // rtk/hooks
  const [signUserIn, { isLoading: isUserSigningIn }] = useLogUserInMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useIsSmallScreen();
  // queryparam which determines whether we're here from the attendpartylink flow
  const [attendPartyLink] = useQueryParam("inviteLink", StringParam);

  const onSubmit = async (): Promise<void> => {
    if (!phoneNumber || !password) {
      return;
    }
    await signUserIn({ username: phoneNumber, password })
      .unwrap()
      .then((value) => {
        if (value.success) {
          // depending on what context we're in we either want to lead them to homepage or back to a sign in link
          if (attendPartyLink) {
            dispatch(eventMonkeyApi.util.resetApiState());
            navigate(`/event/invite/${attendPartyLink}`);
          } else {
            navigate("/");
          }
        } else {
          toast.error("Invalid Credentials", { duration: 1500 });
        }
      });
  };

  return (
    <Stack spacing={1} alignItems="center" width="60%">
      <Toaster position="top-center" />
      <MuiTelInput
        value={phoneNumber}
        defaultCountry="US"
        onChange={(value: string, info: MuiTelInputInfo): void => {
          setPhoneNumber(info.numberValue || "");
        }}
        sx={{ width: "100%" }}
      />
      <TextField
        sx={{ width: "100%" }}
        label="Password"
        type="password"
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            onSubmit();
          }
        }}
        onChange={(e: any): void => {
          setPassword(e.target.value);
        }}
      />
      <MonkeyButton
        onClick={onSubmit}
        text="Sign in"
        disabled={isUserSigningIn || !password || !phoneNumber}
      />
      <MonkeyButton
        onClick={(): void => {
          navigate("/forgot-password");
        }}
        textSize="body2"
        disabled={isUserSigningIn}
        text="Forgot password?"
      />
      <Stack
        paddingTop={isSmallScreen ? "50px" : undefined}
        direction={isSmallScreen ? "column" : "row"}
        spacing={isSmallScreen ? undefined : 1}
        alignItems="center"
      >
        <Typography component="span" variant="body1">
          Don't have an account?
        </Typography>
        <MonkeyButton
          onClick={(): void => {
            navigate("/sign-up");
          }}
          disabled={isUserSigningIn}
          text="Sign Up"
        />
      </Stack>
    </Stack>
  );
};
