/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AutoFlowPartyRequest,
  AutoFlowPartyResponse,
  Event,
  Guest,
  Performer,
  RespondToEventRequest,
  RespondToEventResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Events<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags events
   * @name EventsList
   * @request GET:/events/
   * @secure
   */
  eventsList = (params: RequestParams = {}) =>
    this.request<Event[], any>({
      path: `/events/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags events
   * @name EventsCreate
   * @request POST:/events/
   * @secure
   */
  eventsCreate = (data: Event, params: RequestParams = {}) =>
    this.request<Event, any>({
      path: `/events/`,
      method: "POST",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description List the events a user is hosting
   *
   * @tags events
   * @name GetEventsForHost
   * @request GET:/events/get_events_for_host/
   * @secure
   */
  getEventsForHost = (params: RequestParams = {}) =>
    this.request<Event[], any>({
      path: `/events/get_events_for_host/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Make a request for event creation via auto flow
   *
   * @tags events
   * @name MakeAutoFlowRequest
   * @request POST:/events/make_auto_flow_request/
   * @secure
   */
  makeAutoFlowRequest = (data: AutoFlowPartyRequest, params: RequestParams = {}) =>
    this.request<AutoFlowPartyResponse, any>({
      path: `/events/make_auto_flow_request/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags events
   * @name EventsRead
   * @request GET:/events/{id}/
   * @secure
   */
  eventsRead = (id: string, params: RequestParams = {}) =>
    this.request<Event, any>({
      path: `/events/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags events
   * @name EventsUpdate
   * @request PUT:/events/{id}/
   * @secure
   */
  eventsUpdate = (id: string, data: Event, params: RequestParams = {}) =>
    this.request<Event, any>({
      path: `/events/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags events
   * @name EventsPartialUpdate
   * @request PATCH:/events/{id}/
   * @secure
   */
  eventsPartialUpdate = (id: string, data: Event, params: RequestParams = {}) =>
    this.request<Event, any>({
      path: `/events/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description list guests for an event
   *
   * @tags events
   * @name GuestsForEvent
   * @request GET:/events/{id}/list_guests_for_event/
   * @secure
   */
  guestsForEvent = (id: string, params: RequestParams = {}) =>
    this.request<Guest[], any>({
      path: `/events/${id}/list_guests_for_event/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description list guests for an event
   *
   * @tags events
   * @name PerformersForEvent
   * @request GET:/events/{id}/list_performers_for_event/
   * @secure
   */
  performersForEvent = (id: string, params: RequestParams = {}) =>
    this.request<Performer[], any>({
      path: `/events/${id}/list_performers_for_event/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Respond to an event
   *
   * @tags events
   * @name RespondToEvent
   * @request POST:/events/{id}/respond_to_event/
   * @secure
   */
  respondToEvent = (id: string, data: RespondToEventRequest, params: RequestParams = {}) =>
    this.request<RespondToEventResponse, any>({
      path: `/events/${id}/respond_to_event/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
