import { z } from "zod";
import { minMaxFormSchema } from "../logic/types";

export const performersCreateFormSchema = z.object({
  type: z.string().min(1), // the id's
  specialties: z.array(z.string()).optional(), // the id's
  priceRange: minMaxFormSchema,
  address: z.string().optional(),
  socialLinks: z.array(z.string().url()),
});

export type PerformerCreate = z.infer<typeof performersCreateFormSchema>;
