import { Stack, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import { useNavigate } from "react-router";
import { AutoFlowCreationRequest } from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import {
  useGetUserInfoQuery,
  useListAutoFlowRequestsQuery,
} from "../endpoints";

const RequestsSection = (): React.ReactElement => {
  const navigate = useNavigate();
  const { data: autoflowRequests, isLoading } = useListAutoFlowRequestsQuery();

  if (isLoading) {
    return <></>;
  }

  if (!autoflowRequests) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  if (!autoflowRequests.length) {
    return <></>;
  }

  return (
    <>
      <Typography component="span" variant="h4">
        Event Requests
      </Typography>
      {autoflowRequests
        .filter(
          (request: AutoFlowCreationRequest) =>
            !request.response_params && !!request.id
        )
        .map((request: AutoFlowCreationRequest, idx: number) => {
          const formattedDate = format(
            parseISO(request.request_params.party_date),
            "MM-dd"
          );
          const displayString = `${request.requesting_user_str} - ${formattedDate}`;
          return (
            <MonkeyButton
              key={request.id!}
              text={displayString}
              onClick={(): void => {
                navigate(`/admin/autoflow-responder/${request.id!}`);
              }}
            />
          );
        })}
    </>
  );
};

export const AdminCenter = (): React.ReactElement => {
  const navigate = useNavigate();
  const { data: userData, isLoading: isUserLoading } = useGetUserInfoQuery();

  if (isUserLoading) {
    return <></>;
  }

  if (!userData) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  if (!userData.is_superuser) {
    return (
      <Typography component="span" variant="body1">
        This page is reserved to admins
      </Typography>
    );
  }

  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography component="span" variant="body1">
          Location Uploader
        </Typography>
        <MonkeyButton
          text="Go"
          onClick={(): void => {
            navigate("/admin/location-uploader");
          }}
        />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography component="span" variant="body1">
          Performer Uploader
        </Typography>
        <MonkeyButton
          text="Go"
          onClick={(): void => {
            navigate("/admin/performer-uploader");
          }}
        />
      </Stack>
      <RequestsSection />
    </Stack>
  );
};
