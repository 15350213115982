import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import {
  MonkeyUser,
  Performer,
  PerformerSpecialty,
  PerformerType,
} from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import { MonkeyTextField } from "../components/MonkeyTextField";
import { NumberInput } from "../components/NumberInput";
import {
  useCreatePerformerMutation,
  useGetPerformerForCurrUserQuery,
  useGetUserInfoQuery,
  useLazyGetLocationForAddyQuery,
  useListPerformerSpecialtiesQuery,
  useListPerformerTypesQuery,
  useUpdatePerformerMutation,
} from "../endpoints";
import { MinMax } from "../logic/types";
import { PerformerCreate, performersCreateFormSchema } from "./types";

export const PerformersCreate = (): React.ReactElement => {
  const { data: userInfo, isLoading: isUserLoading } = useGetUserInfoQuery();
  const { data: performerData, isLoading: isPerformerLoading } =
    useGetPerformerForCurrUserQuery();

  if (isUserLoading || isPerformerLoading) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }
  if (!userInfo) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <PerformersEdit
      userInfo={userInfo}
      performerData={performerData || undefined}
    />
  );
};

const PerformersEdit = ({
  userInfo,
  performerData,
}: {
  userInfo: MonkeyUser;
  performerData: Performer | undefined;
}): React.ReactElement => {
  // initial use state
  const [locationError, setLocationError] = React.useState<string | undefined>(
    undefined
  );
  const initialAddress = performerData?.address;

  // RTK
  const [lazyGetLocationObj] = useLazyGetLocationForAddyQuery();

  // form setup
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<PerformerCreate>({
    resolver: zodResolver(performersCreateFormSchema),
    defaultValues: {
      type: performerData?.type ?? "",
      specialties: performerData?.specialties ?? [],
      priceRange: performerData?.price_range
        ? (performerData?.price_range as MinMax)
        : { min: null, max: null },
      address: initialAddress,
      socialLinks: performerData?.social_links?.length
        ? performerData?.social_links
        : [""],
    },
  });

  const typeVal = getValues("type");
  const setType = React.useCallback(
    (newVal: string): void => {
      setValue("type", newVal, { shouldValidate: true });
    },
    [setValue]
  );
  const specialtiesVal = getValues("specialties");
  const setSpecialties = React.useCallback(
    (newVal: string[]): void => {
      setValue("specialties", newVal, { shouldValidate: true });
    },
    [setValue]
  );

  const priceRangeVal = getValues("priceRange");
  const setPriceRange = React.useCallback(
    (newVal: MinMax): void => {
      setValue("priceRange", newVal, { shouldValidate: true });
    },
    [setValue]
  );
  const addressVal = getValues("address");
  const setAddress = React.useCallback(
    (newVal: string): void => {
      setValue("address", newVal, { shouldValidate: true });
    },
    [setValue]
  );
  const linksVal = getValues("socialLinks");

  // RTK
  const [createPerformer] = useCreatePerformerMutation();
  const [updatePerformer] = useUpdatePerformerMutation();
  const { data: performerSpecialties, isLoading: isSpecialtiesLoading } =
    useListPerformerSpecialtiesQuery();
  const { data: performerTypes, isLoading: isTypesLoading } =
    useListPerformerTypesQuery();

  // callbacks
  const onSubmit = async (): Promise<void> => {
    if (!typeVal || !userInfo?.id) {
      return;
    }

    let locationId = performerData?.location;
    // TODO: add support to clear out address
    if (addressVal && addressVal !== initialAddress) {
      const locationFetchResponse = await lazyGetLocationObj({
        address: addressVal,
      });
      locationId = locationFetchResponse.data?.location?.id;
      const message = locationFetchResponse.data?.message;
      if (message || !locationId) {
        setLocationError(
          message || "Something went wrong. Please try a different address."
        );
        return;
      }
    }

    const callable = performerData ? updatePerformer : createPerformer;

    await callable({
      id: performerData?.id,
      user: userInfo.id,
      type: typeVal,
      specialties: specialtiesVal,
      price_range: priceRangeVal,
      location: locationId,
      social_links: linksVal,
    }).unwrap();
  };

  // TSX
  if (isSpecialtiesLoading || isTypesLoading) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }

  if (!performerSpecialties || !performerTypes) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack width="300px" spacing={2} alignItems="center">
        <Typography component="span" variant="body1">
          What kind of performer are you?
        </Typography>
        <Autocomplete
          options={performerTypes}
          value={
            performerTypes.find(
              (currType) => !!currType.id && typeVal === currType.id
            ) ?? null
          }
          sx={{ width: "100%" }}
          onChange={(_: any, newValue: PerformerType | null): void => {
            if (!newValue || !newValue.id) {
              return;
            }
            setSpecialties([]);
            setType(newValue.id);
          }}
          // freeSolo
          autoSelect
          // @ts-ignore
          getOptionLabel={(option: PerformerType) => {
            if (typeof option === "string") {
              return option;
            }
            return option.name;
          }}
          renderInput={(params) => <TextField {...params} label="Type" />}
        />
        <Autocomplete
          disabled={!typeVal}
          options={performerSpecialties.filter(
            (specialty: PerformerSpecialty): boolean => {
              return (
                !!specialty.id &&
                !!performerTypes
                  .find((currType: PerformerType): any => {
                    return currType.id === typeVal;
                  })
                  ?.specialties.includes(specialty.id)
              );
            }
          )}
          value={performerSpecialties.filter(
            (specialty) =>
              !!specialty.id && specialtiesVal?.includes(specialty.id)
          )}
          sx={{ width: "100%" }}
          onChange={(
            _: any,
            newValue: (string | PerformerSpecialty)[]
          ): void => {
            setSpecialties(
              newValue.map((thingy: string | PerformerSpecialty): string => {
                if (typeof thingy === "string") {
                  return thingy;
                } else {
                  return thingy.id!;
                }
              })
            );
          }}
          multiple
          freeSolo
          autoSelect
          // @ts-ignore
          getOptionLabel={(option: PerformerSpecialty) => {
            if (typeof option === "string") {
              return option;
            }
            return option.name;
          }}
          renderInput={(params) => <TextField {...params} label="Specialty" />}
        />
        <Typography component="span" variant="body1">
          Your rate ($USD/hour)
        </Typography>
        <Stack direction="row" spacing={1}>
          <NumberInput
            value={priceRangeVal.min ?? null}
            onChange={(newVal: number | undefined): void =>
              setPriceRange({ min: newVal, max: priceRangeVal.max })
            }
            label="Minimum"
          />
          <NumberInput
            value={priceRangeVal.max ?? null}
            onChange={(newVal: number | undefined): void =>
              setPriceRange({ min: priceRangeVal.min, max: newVal })
            }
            label="Maximum"
          />
        </Stack>
        <MonkeyTextField
          value={addressVal}
          errorMessage={locationError}
          onChange={(event): void => {
            setLocationError(undefined);
            setAddress(event.target.value);
          }}
          label="Location"
          fullWidth
        />
        {linksVal.map((link: string, idx: number) => {
          const errorMessage =
            (errors.socialLinks?.length || 0) >= idx + 1 &&
            errors.socialLinks?.[idx]?.message;
          return (
            <Stack
              direction="row"
              width="100%"
              alignItems="flex-start"
              key={idx}
            >
              <MonkeyTextField
                label="Social link"
                defaultValue={link}
                onChange={(event) => {
                  const copiedPrev = [...linksVal];
                  copiedPrev[idx] = event.target.value ?? "";
                  setValue("socialLinks", copiedPrev, { shouldValidate: true });
                }}
                errorMessage={errorMessage || undefined}
                fullWidth
              />
              <Box
                // dont like this at all but height isn't behaving how i want
                sx={{ height: "53px" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <MonkeyButton
                  text="x"
                  onClick={() => {
                    const copiedPrev = [...linksVal];
                    copiedPrev.splice(idx, 1);
                    setValue("socialLinks", copiedPrev, {
                      shouldValidate: true,
                    });
                  }}
                />
              </Box>
            </Stack>
          );
        })}
        <MonkeyButton
          text="Add another link"
          onClick={() => {
            if (!linksVal.length || !!linksVal[linksVal.length - 1]) {
              setValue("socialLinks", [...linksVal, ""], {
                shouldValidate: true,
              });
            }
          }}
        />
        <MonkeyButton
          text={performerData ? "Update" : "Create"}
          type="submit"
        />
      </Stack>
    </form>
  );
};
