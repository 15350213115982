import { useNavigate, useParams } from "react-router-dom";

import { Stack, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { MonkeyButton } from "../components/Button";
import {
  useGetEventQuery,
  useGetGuestViaLinkQuery,
  useGetUserInfoQuery,
  useResponseToInviteMutation,
  useViewInviteQuery,
} from "../endpoints";
import { GuestStatusEnum } from "./types";

export const InviteAccept = (): React.ReactElement => {
  const { inviteId } = useParams();
  const [respondToInvite, { isLoading: isRespondingToEvent }] =
    useResponseToInviteMutation();
  const navigate = useNavigate();
  const { data: inviteData, isLoading: isInviteLoading } = useViewInviteQuery(
    inviteId ?? skipToken
  );
  const {
    data: userData,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useGetUserInfoQuery();

  const { data: guestData, isLoading: isGuestLoading } =
    useGetGuestViaLinkQuery(inviteId ?? skipToken);

  const {
    data: eventData,
    isLoading: isEventLoading,
    isError,
  } = useGetEventQuery(guestData?.event ? guestData?.event : skipToken);

  // callback to respond to an event - TODO: Should we event have this as an option?
  const respondToInviteWrapper = React.useCallback(
    async (guest_status: GuestStatusEnum): Promise<void> => {
      if (!inviteId || !eventData?.id) {
        return;
      }

      const respondData = {
        id: inviteId,
        data: {
          plus_one_count: 0,
          guest_status: guest_status,
        },
      };

      await respondToInvite(respondData).then((_) =>
        navigate(`/event/view/${eventData.id}`)
      );
    },
    [eventData?.id, inviteId, navigate, respondToInvite]
  );

  // some postprocessing to show the invite to the user - TODO: Should we just dump the user into the party thing, with maybe an overlay?
  const inviterText = inviteData?.inviter_name
    ? `${inviteData.inviter_name} invited you to`
    : "You've been invited to";
  let hostText = eventData?.host_names?.length
    ? `${eventData?.host_names}'s`
    : "a";
  if (
    eventData?.host_names?.length &&
    inviteData?.inviter_name &&
    eventData?.host_names.includes(inviteData.inviter_name)
  ) {
    hostText = "their";
  }

  // Depending on whether a guest is already a user, they can respond straight up, or first have to create an account

  const AcceptOrCreateText = React.useMemo(() => {
    if (!guestData?.user_id) {
      return (
        <Typography component="span" variant="body1">
          You must first create an account to respond to this invite. It'll only
          take a second!
        </Typography>
      );
    } else if (!userData?.id) {
      return (
        <Typography component="span" variant="body1">
          You must first log in to respond to this invite.
        </Typography>
      );
      // the user shouldn't see this because we'll deal with this case elsewhere. but just for safety
    } else if (userData?.id !== guestData?.user_id) {
      return (
        <Typography component="span" variant="body1">
          This invite isn't for you. You can't reply to this.
        </Typography>
      );
    }
    return (
      <Typography component="span" variant="body1">
        Press here to respond to the invite:
      </Typography>
    );
  }, [guestData?.user_id, userData?.id]);

  const AcceptOrCreateButton = React.useMemo(() => {
    // this guest hasnt created an account yet
    if (!guestData?.user_id) {
      return (
        <MonkeyButton
          text="Create account"
          onClick={(): void => navigate(`/sign-up?inviteLink=${inviteId}`)}
        />
      );
      // the user isn't authed yet
    } else if (!userData?.id) {
      return (
        <MonkeyButton
          text="Log in"
          onClick={(): void => navigate(`/sign-in?inviteLink=${inviteId}`)}
        />
      );
      // IS AUTHED but this isn't their link
    } else if (userData?.id !== guestData?.user_id) {
      return <></>;
    }
    return (
      <Stack direction="row" width="100%" justifyContent="center">
        <MonkeyButton
          text="Accept"
          disabled={isRespondingToEvent}
          onClick={(): Promise<void> =>
            respondToInviteWrapper(GuestStatusEnum.YES)
          }
        />
        <MonkeyButton
          text="Maybe"
          disabled={isRespondingToEvent}
          onClick={(): Promise<void> =>
            respondToInviteWrapper(GuestStatusEnum.MAYBE)
          }
        />
        <MonkeyButton
          text="Decline"
          disabled={isRespondingToEvent}
          onClick={(): Promise<void> =>
            respondToInviteWrapper(GuestStatusEnum.NO)
          }
        />
        <MonkeyButton
          text="Ignore"
          disabled={isRespondingToEvent}
          onClick={(): Promise<void> =>
            respondToInviteWrapper(GuestStatusEnum.NO_RESPONSE)
          }
        />
      </Stack>
    );
  }, [
    guestData?.user_id,
    inviteId,
    isRespondingToEvent,
    navigate,
    respondToInviteWrapper,
    userData?.id,
  ]);

  if (isGuestLoading || isInviteLoading || isEventLoading || isUserLoading) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }

  if (!inviteData) {
    return (
      <Stack spacing={1} alignItems="center">
        <Typography component="span" variant="body1">
          This invite no longer exists.
        </Typography>
        <Typography component="span" variant="body1">
          It has likely already been used, or the link may just be wrong...
        </Typography>
      </Stack>
    );
  }

  if (guestData?.user_id && userData?.id && guestData.user_id !== userData.id) {
    return (
      <Stack spacing={1} alignItems="center">
        <Typography component="span" variant="body1">
          This invite was not addressed to you.
        </Typography>
        <Typography component="span" variant="body1">
          If you think it's a mistake, you may be logged in to the wrong account
          or ask the host to send a new invite
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={6} alignItems="center">
      <Typography component="span" variant="h4">
        Welcome!
      </Typography>
      <Stack spacing={1} alignItems="center">
        <Typography component="span" variant="body1">
          {inviterText} {hostText} party
        </Typography>
        {AcceptOrCreateText}
      </Stack>
      {AcceptOrCreateButton}
    </Stack>
  );
};
