import { MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { GuestStatusEnum } from "../Events/types";
import { Event, GuestAtEvent } from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import {
  useListEventsAsGuestQuery,
  useListEventsQuery,
  useRespondToEventMutation,
} from "../endpoints";
import { useIsSmallScreen } from "../logic/hooks";

export const EventsBrowser = (): React.ReactElement => {
  // RTK/Hooks
  const { data: eventsData, isLoading: isEventsDataLoading } =
    useListEventsQuery();
  const [respondToEvent] = useRespondToEventMutation();
  const { data: eventsAsGuest, isLoading: isLoadingAsGuest } =
    useListEventsAsGuestQuery();
  const navigate = useNavigate();
  const isSmallScreen = useIsSmallScreen();

  // Callbacks
  const getGuestResponse = (event: Event): GuestAtEvent | undefined => {
    return eventsAsGuest?.find(
      (eAndGInfo) => eAndGInfo.event_info.id === event.id
    );
  };

  if (isEventsDataLoading || isLoadingAsGuest) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }
  if (!eventsData?.length) {
    return (
      <Typography component="span" variant="body1">
        There are no events in your area. Check back in later for better luck!
      </Typography>
    );
  }
  return (
    <Stack spacing={1} width="100%" alignItems="center">
      {eventsData?.map((event: Event) => {
        const guestAtEventResponse = getGuestResponse(event);
        const guestStatus = guestAtEventResponse?.guest_info?.status;

        return (
          <Stack
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
            direction="row"
            key={event.id ?? event.name}
            width={isSmallScreen ? "100%" : "50%"}
          >
            <MonkeyButton
              onClick={(): void => {
                navigate(`/event/view/${event.id}`);
              }}
              text={event.name}
            />
            <TextField
              select
              sx={{ minWidth: "145px", width: "145px" }}
              label="Attending?"
              value={guestStatus || GuestStatusEnum.NO_RESPONSE}
              onChange={(textFieldEvent: any): void => {
                if (!event.id) {
                  return;
                }
                respondToEvent({
                  eventId: event.id,
                  status: textFieldEvent.target.value,
                });
              }}
              variant="outlined"
            >
              <MenuItem value={GuestStatusEnum.YES}>Yes</MenuItem>
              <MenuItem value={GuestStatusEnum.NO}>No</MenuItem>
              <MenuItem value={GuestStatusEnum.MAYBE}>Maybe</MenuItem>
              <MenuItem value={GuestStatusEnum.NO_RESPONSE}>
                No response
              </MenuItem>
            </TextField>
          </Stack>
        );
      })}
    </Stack>
  );
};
