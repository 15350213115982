import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Performer } from "../../apiClient/data-contracts";

interface PerformerAutocompleteProps {
  performerId?: string;
  options: Performer[];
  onChange: (event: any, newValue: Performer | null) => void;
  labelOverride?: string;
}

export const PerformerAutocomplete = ({
  performerId,
  options,
  onChange,
  labelOverride,
}: PerformerAutocompleteProps): React.ReactElement => {
  return (
    <Autocomplete
      options={options ?? []}
      sx={{ width: "100%" }}
      value={
        options?.find((option: Performer) => option.id === performerId) ?? null
      }
      // @ts-ignore
      onChange={onChange}
      // @ts-ignore
      getOptionLabel={(option: Performer) => {
        return option.name;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={labelOverride ?? "Performer"} />
      )}
    />
  );
};
