/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContactInfo } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class ContactInfos<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags contact-infos
   * @name ContactInfosCreate
   * @request POST:/contact-infos/
   * @secure
   */
  contactInfosCreate = (data: ContactInfo, params: RequestParams = {}) =>
    this.request<ContactInfo, any>({
      path: `/contact-infos/`,
      method: "POST",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags contact-infos
   * @name ContactInfosRead
   * @request GET:/contact-infos/{id}/
   * @secure
   */
  contactInfosRead = (id: string, params: RequestParams = {}) =>
    this.request<ContactInfo, any>({
      path: `/contact-infos/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags contact-infos
   * @name ContactInfosUpdate
   * @request PUT:/contact-infos/{id}/
   * @secure
   */
  contactInfosUpdate = (id: string, data: ContactInfo, params: RequestParams = {}) =>
    this.request<ContactInfo, any>({
      path: `/contact-infos/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags contact-infos
   * @name ContactInfosPartialUpdate
   * @request PATCH:/contact-infos/{id}/
   * @secure
   */
  contactInfosPartialUpdate = (id: string, data: ContactInfo, params: RequestParams = {}) =>
    this.request<ContactInfo, any>({
      path: `/contact-infos/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
}
