import AccountBoxIcon from "@mui/icons-material/AccountBox";

import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Box, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { LogOutButton } from "./AccountManagement/LogOutButton";
import { Notification } from "./apiClient/data-contracts";
import { ReactComponent as HomeIcon } from "./assets/icons/home_icon.svg";
import { ReactComponent as LocationIcon } from "./assets/icons/location_icon.svg";
import { ReactComponent as MusicIcon } from "./assets/icons/music_icon.svg";
import { ReactComponent as NotificationsIcon } from "./assets/icons/notif_icon.svg";
import { ReactComponent as PartyIcon } from "./assets/icons/party_icon.svg";
import {
  useGetUserInfoQuery,
  useIsLoggedInQuery,
  useListNotificationsForUserQuery,
} from "./endpoints";
import theme from "./theme";
const NotificationsContent = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement => {
  const { data: notifsData, isLoading: isNotifsLoading } =
    useListNotificationsForUserQuery();
  const navigate = useNavigate();

  if (isNotifsLoading) {
    return (
      <Typography component="span" variant="body1" sx={{ padding: "8px" }}>
        Loading...
      </Typography>
    );
  }

  if (!notifsData?.length) {
    return (
      <Typography component="span" variant="body1" sx={{ padding: "8px" }}>
        No notifications yet!
      </Typography>
    );
  }

  return (
    <Stack spacing={1}>
      {notifsData?.map((notif: Notification) => {
        let linkToNavTo: string | undefined = undefined;

        // auto flow creation request
        // @ts-ignore
        if (notif.content_type_id === 12 && !!notif.object_uuid) {
          linkToNavTo = `/autoflow/view/${notif.object_uuid}`;
        }
        // event
        // @ts-ignore
        if (notif.content_type_id === 11 && !!notif.object_uuid) {
          linkToNavTo = `/event/view/${notif.object_uuid}`;
        }
        return (
          <MenuItem
            key={notif.id}
            sx={{
              margin: "8px",
              width: "100%",
            }}
            onClick={(): void => {
              if (!linkToNavTo) {
                return;
              }
              navigate(linkToNavTo);
              onClose();
            }}
          >
            <Stack spacing={1} width="100%">
              <Typography
                component="span"
                variant="body1"
                fontWeight="700"
                fontSize="18px"
              >
                {notif.subject}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                sx={{
                  maxWidth: "250px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {notif.body}
              </Typography>
            </Stack>
          </MenuItem>
        );
      })}
    </Stack>
  );
};

const NotificationSection = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={1}>
      <Box
        sx={{ ":hover": { cursor: "pointer" } }}
        display="flex"
        alignItems="center"
        onClick={(): void => navigate("/browse-locations")}
      >
        <LocationIcon width="26px" />
      </Box>
      <Box
        sx={{ ":hover": { cursor: "pointer" } }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={(): void => navigate("/browse-performers")}
      >
        <MusicIcon />
      </Box>
      <Box
        onClick={(): void => navigate("/event/create")}
        sx={{ ":hover": { cursor: "pointer" } }}
        display="flex"
        alignItems="center"
        paddingLeft="7px"
      >
        <PartyIcon width="25px" />
      </Box>
      <Box
        onClick={(event): void => {
          // @ts-ignore
          setAnchorEl(event.currentTarget);
        }}
        sx={{ ":hover": { cursor: "pointer" } }}
        display="flex"
        alignItems="center"
        paddingLeft="7px"
      >
        {/* <NotificationsIcon sx={{ color: theme.palette.secondary.main }} /> */}
        <NotificationsIcon width="25px" />
      </Box>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={(): void => setAnchorEl(null)}
      >
        <Box
          minWidth="200px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <NotificationsContent onClose={(): void => setAnchorEl(null)} />
        </Box>
      </Menu>
    </Stack>
  );
};

export const NavBar = (): React.ReactElement => {
  const navigate = useNavigate();
  const { data: isLoggedIn } = useIsLoggedInQuery();
  const { data: userData } = useGetUserInfoQuery();

  return (
    <Stack
      height="60px"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      paddingX="16px"
    >
      <Stack spacing={2} direction="row" minWidth={0}>
        {isLoggedIn && (
          <>
            <Box
              onClick={(): void => {
                navigate("/");
              }}
              sx={{ ":hover": { cursor: "pointer" } }}
              display="flex"
              alignItems="center"
            >
              <HomeIcon />
              {/* <HomeIcon sx={{ color: theme.palette.secondary.main }} /> */}
            </Box>
            <NotificationSection />

            <Box
              onClick={(): void => {
                navigate("/profile");
              }}
              sx={{ ":hover": { cursor: "pointer" } }}
              display="flex"
              alignItems="center"
            >
              <AccountBoxIcon sx={{ color: theme.palette.secondary.main }} />
            </Box>
            {userData?.is_superuser && (
              <Box
                onClick={(): void => {
                  navigate("/admin");
                }}
                sx={{ ":hover": { cursor: "pointer" } }}
                display="flex"
                alignItems="center"
              >
                <SupervisorAccountIcon
                  sx={{ color: theme.palette.secondary.main }}
                />
              </Box>
            )}
          </>
        )}
      </Stack>
      <LogOutButton />
    </Stack>
  );
};
