import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { store } from "./store";

import { Box, Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Provider } from "react-redux";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { SignIn } from "./AccountManagement/SignIn";
import { SignUp } from "./AccountManagement/SignUp";
import { EventEditorWrapper } from "./Events/EditEvent";

import React from "react";
import { QueryParamProvider } from "use-query-params";
import { ForgotPassword } from "./AccountManagement/ForgotPassword";
import { OTPPage } from "./AccountManagement/OtpPage";
import { GeneralBrowse } from "./Browse";
import { EventsBrowser } from "./Browse/EventsBrowser";
import { LocationBrowse } from "./Browse/LocationBrowse";
import { LocationView } from "./Browse/LocationView";
import { PerformerView } from "./Browse/PerformerView";
import { CreateAuto } from "./Events/AutoCreate/CreateAuto";
import { ViewAutoAndResponse } from "./Events/AutoCreate/ViewAuto";
import { CreateHomeScreen } from "./Events/CreateHomeScreen";
import { EventView } from "./Events/EventView";
import { InviteAccept } from "./Events/InviteAccept";
import { HomeWrapper } from "./HomePage/Home";
import { LoadingPage } from "./HomePage/LoadingPage";
import { LocationUploader } from "./Locations/Uploader/components/LocationUploader";
import { PerformerUploader } from "./Locations/Uploader/components/PerformerUploader";
import { NavBar } from "./NavBar";
import { PerformersCreate } from "./Performers/CreatePerformer";
import { UserProfile } from "./Users";
import { AdminCenter } from "./admin";
import { AutoFlowResponderWrapper } from "./admin/AutoFlowResponder";
import { useIsLoggedInQuery } from "./endpoints";
import { useIsSmallScreen } from "./logic/hooks";
import theme from "./theme";

const PUBLIC_FACING_PATHS = [
  "/",
  "/sign-in",
  "/sign-up",
  "/forgot-password",
  "/event/invite",
  "/otp",
];

const DecoratedApp = React.memo((): React.ReactElement => {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { data: isLoggedIn, isLoading: isLoggedInLoading } =
    useIsLoggedInQuery();

  const isPublicFacing = !!PUBLIC_FACING_PATHS.find((publicPath) =>
    publicPath !== "/"
      ? currentPath.includes(publicPath)
      : currentPath === publicPath
  );

  if (!isLoggedIn && !isLoggedInLoading && !isPublicFacing) {
    navigate("/");
    return <LoadingPage />;
  }

  return (
    <Stack
      height="100vh"
      width="100vw"
      sx={{ backgroundColor: theme.palette.background.default }}
    >
      <NavBar />
      <Box
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          spacing={2}
          width={isSmallScreen ? "80%" : "50%"}
          marginY="30px"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          {isLoggedInLoading ? (
            <LoadingPage />
          ) : (
            <Routes>
              <Route path="/" element={<HomeWrapper />} />
              {/* admin routes */}
              <Route path="/admin" element={<AdminCenter />} />
              <Route
                path="/admin/autoflow-responder/:requestId"
                element={<AutoFlowResponderWrapper />}
              />
              <Route
                path="/admin/location-uploader/"
                element={<LocationUploader />}
              />
              <Route
                path="/admin/performer-uploader/"
                element={<PerformerUploader />}
              />
              {/* Profile/user update */}
              <Route path="/profile" element={<UserProfile />} />

              {/* registration/login */}
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/otp" element={<OTPPage />} />
              {/* Monkeys */}
              <Route path="/browse-locations" element={<LocationBrowse />} />
              <Route path="/browse-performers" element={<GeneralBrowse />} />
              <Route path="/performers/create" element={<PerformersCreate />} />
              <Route
                path="/performers/view/:performerId"
                element={<PerformerView />}
              />
              <Route
                path="/locations/view/:locationId"
                element={<LocationView />}
              />
              {/* Events */}
              <Route path="/event/create" element={<CreateHomeScreen />} />
              <Route path="/event/create-auto" element={<CreateAuto />} />
              <Route
                path="/autoflow/view/:requestId"
                element={<ViewAutoAndResponse />}
              />
              <Route
                path="/event/edit/:eventId?"
                element={<EventEditorWrapper />}
              />
              <Route path="/event/view/:eventId" element={<EventView />} />
              <Route
                path="/event/invite/:inviteId"
                element={<InviteAccept />}
              />
              <Route path="/browse-events" element={<EventsBrowser />} />
            </Routes>
          )}
        </Stack>
      </Box>
    </Stack>
  );
});

const App = (): React.ReactElement => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <DndProvider backend={HTML5Backend}>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <DecoratedApp />
              </QueryParamProvider>
            </DndProvider>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
