import { Box, Typography } from "@mui/material";

export const MonkeyLoading = (): React.ReactElement => {
  return (
    <Box
      width="100%"
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    </Box>
  );
};
