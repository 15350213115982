import { Stack, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { skipToken } from "@reduxjs/toolkit/query";
import { parseISO } from "date-fns";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { RoughLocationSelect } from "../../Locations/Map/components/RoughLocSelect";
import { RoughLocSchema } from "../../Locations/Map/types";
import {
  AutoFlowCreationRequest,
  AutoFlowPackage,
  AutoFlowPartyRequest,
  Performer,
  PerformerSpecialty,
  PerformerType,
} from "../../apiClient/data-contracts";
import { MonkeyButton } from "../../components/Button";
import { MonkeyLoading } from "../../components/Loading";
import { MonkeyTextField } from "../../components/MonkeyTextField";
import {
  useGetAutoFlowRequestQuery,
  useGetLocationQuery,
  useListPerformerSpecialtiesQuery,
  useListPerformerTypesQuery,
  useListPerformersQuery,
  useUpdateAutoFlowRequestMutation,
} from "../../endpoints";
import { useGetDisplayForPerformer } from "../../helpers/hooks";
import { getDisplayForMinMaxObj } from "../../helpers/utils";

// Big tech debt
// organize the shit in this file

const LocationSegment = ({
  requestParams,
}: {
  requestParams: AutoFlowPartyRequest;
}): React.ReactElement => {
  const locationId = requestParams.specific_location;
  const { data: locationData, isLoading } = useGetLocationQuery(
    locationId ? locationId : skipToken
  );
  if (isLoading) {
    return <MonkeyLoading />;
  }

  if (locationData) {
    return (
      <Typography component="span" variant="body1">
        Location: {locationData.clean_address}
      </Typography>
    );
  } else if (requestParams.rough_location) {
    return (
      <RoughLocationSelect
        initLoc={{
          lat: requestParams.rough_location.lat,
          lng: requestParams.rough_location.lon,
        }}
        initRadius={requestParams.rough_location.radius}
        setRoughLoc={(k: RoughLocSchema | undefined) => console.log(k)}
        editable={false}
        containerStyle={{ width: "300px", height: "300px" }}
      />
    );
  }
  return <></>;
};

const BudgetSegment = ({
  requestParams,
}: {
  requestParams: AutoFlowPartyRequest;
}): React.ReactElement => {
  return (
    <Typography component="span" variant="body1">
      Specified budget:{" "}
      {getDisplayForMinMaxObj({
        minMaxObj: requestParams.budget,
        prefix: "$",
        maxPostString: "or less",
      })}
    </Typography>
  );
};

const AttendingSegment = ({
  requestParams,
}: {
  requestParams: AutoFlowPartyRequest;
}): React.ReactElement => {
  return (
    <Typography component="span" variant="body1">
      Attending:{" "}
      {getDisplayForMinMaxObj({
        minMaxObj: requestParams.expected_attending,
        maxPostString: "or less",
      })}
    </Typography>
  );
};

const PerformersSegment = ({
  requestParams,
  performerTypes,
  performerSpecialties,
}: {
  requestParams: AutoFlowPartyRequest;
  performerTypes: PerformerType[];
  performerSpecialties: PerformerSpecialty[];
}): React.ReactElement => {
  if (!requestParams.performer_specs) {
    return <></>;
  }
  const requestedPerformerType = requestParams.performer_specs?.type;
  const requestedSpecialties: (string | undefined)[] =
    requestParams.performer_specs?.specialties;

  const typeToShow = performerTypes.find(
    (type) => type.id === requestedPerformerType
  );
  const specialtiesToShow = performerSpecialties
    .filter((specialty) => requestedSpecialties.includes(specialty.id))
    .map((specialty) => specialty.name)
    .join(", ");

  if (!typeToShow || !specialtiesToShow) {
    return (
      <Typography component="span" variant="body1">
        Error loading performer request
      </Typography>
    );
  }

  return (
    <Typography component="span" variant="body1">
      Requested a {typeToShow.name} specializing in {specialtiesToShow}
    </Typography>
  );
};

export const ViewAuto = ({
  fromAdminView = false,
}: {
  fromAdminView?: boolean;
}): React.ReactElement => {
  const { requestId } = useParams();
  const { data: autoFlowRequestData, isLoading } = useGetAutoFlowRequestQuery(
    requestId ?? skipToken
  );
  const { data: performerTypes, isLoading: isPerformerTypesLoading } =
    useListPerformerTypesQuery();
  const {
    data: performerSpecialties,
    isLoading: isPerformerSpecialtiesLoading,
  } = useListPerformerSpecialtiesQuery();

  if (isLoading || isPerformerSpecialtiesLoading || isPerformerTypesLoading) {
    return <MonkeyLoading />;
  }
  if (!autoFlowRequestData || !performerTypes || !performerSpecialties) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <Stack spacing={2} width="100%" alignItems="center">
      {!fromAdminView && (
        <Typography component="span" variant="h5">
          Your request
        </Typography>
      )}
      <Stack
        spacing={6}
        borderRadius="4px"
        border="1px solid grey"
        padding="32px"
        justifyContent="center"
        alignItems="center"
        width="90%"
      >
        <BudgetSegment requestParams={autoFlowRequestData.request_params} />
        <AttendingSegment requestParams={autoFlowRequestData.request_params} />
        <PerformersSegment
          requestParams={autoFlowRequestData.request_params}
          performerTypes={performerTypes}
          performerSpecialties={performerSpecialties}
        />
        <LocationSegment requestParams={autoFlowRequestData.request_params} />
        {autoFlowRequestData.request_params.description && (
          <MonkeyTextField
            fullWidth
            multiline
            value={`Description: ${autoFlowRequestData.request_params.description}`}
            disabled
          />
        )}
        <DateTimePicker
          sx={{ width: "100%" }}
          disablePast
          defaultValue={
            autoFlowRequestData.request_params.party_date
              ? parseISO(autoFlowRequestData.request_params.party_date)
              : null
          }
          label="Doors Open"
          disabled
        />
      </Stack>
    </Stack>
  );
};

const IndividualPackageView = ({
  pkg,
  performer,
  isPerformersLoading,
  isSelected,
  setAsSelected,
}: {
  pkg: AutoFlowPackage;
  performer: Performer | undefined;
  isPerformersLoading: boolean;
  isSelected: boolean;
  setAsSelected: () => void;
}): React.ReactElement => {
  const displayForPerf = useGetDisplayForPerformer(performer);
  const navigate = useNavigate();
  return (
    <Stack direction="row" width="100%" spacing={1}>
      <Stack
        width="100%"
        borderRadius="4px"
        border={
          isSelected
            ? "2px solid rgba(255, 255, 255, 0.6)"
            : "1px solid rgba(255, 255, 255, 0.1)"
        }
        padding="8px"
        onClick={setAsSelected}
        sx={{ ":hover": { cursor: "pointer" } }}
      >
        <Typography component="span" variant="h5">
          Package: {pkg.name}
        </Typography>
        {/* TODO: Convert this to enzo's price display component */}
        {pkg.price && (
          <Typography component="span" variant="body1">
            {getDisplayForMinMaxObj({
              minMaxObj: pkg.price,
              prefix: "$",
              maxPostString: "max",
            })}
          </Typography>
        )}
        {isPerformersLoading && (
          <Typography component="span" variant="body1">
            Loading...
          </Typography>
        )}
        {performer?.name && (
          <Typography component="span" variant="body1">
            {performer.name}
          </Typography>
        )}
        {displayForPerf && (
          <Typography component="span" variant="body1">
            {displayForPerf}
          </Typography>
        )}
      </Stack>
      <Stack spacing={1}>
        {pkg.performer_id && (
          <MonkeyButton
            text="View performer"
            onClick={(event: React.SyntheticEvent): void => {
              // @ts-ignore
              if (event.metaKey) {
                window.open(
                  `https://eventmonkey.xyz/performers/view/${pkg.performer_id}`
                );
              } else {
                navigate(`/performers/view/${pkg.performer_id}`);
              }
            }}
          />
        )}
        {pkg.location_id && (
          <MonkeyButton
            text="View location"
            onClick={(event: React.SyntheticEvent): void => {
              // @ts-ignore
              if (event.metaKey) {
                window.open(
                  `https://eventmonkey.xyz//locations/view/${pkg.location_id}`
                );
              } else {
                navigate(`/locations/view/${pkg.location_id}`);
              }
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

const ViewAutoResponse = ({
  autoFlowRequestData,
  isFetchingRequest,
}: {
  autoFlowRequestData: AutoFlowCreationRequest;
  isFetchingRequest: boolean;
}): React.ReactElement => {
  const navigate = useNavigate();

  const responseParams = autoFlowRequestData.response_params;
  const alreadySelected = autoFlowRequestData.selected_package;

  const { data: allPerformers, isLoading: isPerformersLoading } =
    useListPerformersQuery();

  const [updateCreationRequest, { isLoading: isUpdatingRequest }] =
    useUpdateAutoFlowRequestMutation();

  const [selectedPkgId, setSelectedPkgId] = React.useState<string | undefined>(
    alreadySelected?.id
  );

  return (
    <Stack spacing={2} width="100%" alignItems="center">
      <Typography component="span" variant="h5">
        Your options
      </Typography>
      <Stack
        spacing={2}
        borderRadius="4px"
        border="1px solid grey"
        padding="32px"
        justifyContent="center"
        alignItems="center"
        width="90%"
      >
        {responseParams?.packages
          .filter((pkg: AutoFlowPackage) => !!pkg.id)
          .map((pkg: AutoFlowPackage, idx: number) => {
            const foundPerformer =
              (pkg.performer_id &&
                allPerformers?.find(
                  (performer: Performer) => performer.id === pkg.performer_id
                )) ||
              undefined;
            const pkgId = pkg.id!;
            return (
              <IndividualPackageView
                pkg={pkg}
                performer={foundPerformer}
                isPerformersLoading={isPerformersLoading}
                key={pkgId}
                isSelected={pkgId === selectedPkgId}
                setAsSelected={(): void => setSelectedPkgId(pkgId)}
              />
            );
          })}
        {!autoFlowRequestData.created_event && (
          <MonkeyButton
            text="Select"
            onClick={async (): Promise<void> => {
              if (selectedPkgId === undefined || !autoFlowRequestData.id) {
                return;
              }
              const selectedPkg = responseParams?.packages.find(
                (pkg: AutoFlowPackage) => pkg.id === selectedPkgId
              );
              if (!selectedPkg) {
                return;
              }
              const updatedRequest = {
                id: autoFlowRequestData.id!,
                user_decision: "ACCEPTED",
                selected_package_json: selectedPkg,
              };
              // @ts-ignore
              updateCreationRequest(updatedRequest)
                .unwrap()
                .then((result: AutoFlowCreationRequest) => {
                  if (result.created_event) {
                    navigate(`/event/view/${result.created_event}`);
                  }
                });
            }}
            disabled={
              selectedPkgId === undefined ||
              !!autoFlowRequestData.created_event ||
              isFetchingRequest ||
              isUpdatingRequest
            }
          />
        )}
        {!!autoFlowRequestData.created_event && (
          <MonkeyButton
            text="View event"
            onClick={(): void =>
              navigate(`/event/view/${autoFlowRequestData.created_event}`)
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

export const ViewAutoAndResponse = (): React.ReactElement => {
  const { requestId } = useParams();
  const { data: autoFlowRequestData, isFetching: isFetchingRequest } =
    useGetAutoFlowRequestQuery(requestId ?? skipToken);
  const [viewingRequest, setViewingRequest] = React.useState(true);
  if (isFetchingRequest) {
    return <MonkeyLoading />;
  }

  if (!autoFlowRequestData?.response_params) {
    return <ViewAuto />;
  }
  return (
    <Stack direction="row" spacing={2} width="100%">
      {viewingRequest && <ViewAuto />}
      <MonkeyButton
        border
        text={viewingRequest ? ">" : "<"}
        onClick={(): void => setViewingRequest((prev) => !prev)}
        height="100%"
      />
      {!viewingRequest && (
        <ViewAutoResponse
          autoFlowRequestData={autoFlowRequestData}
          isFetchingRequest={isFetchingRequest}
        />
      )}
    </Stack>
  );
};
