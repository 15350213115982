/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GetLinksForPerformerResponse, GetPerformerForUserResponse, Performer } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Performers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags performers
   * @name PerformersList
   * @request GET:/performers/
   * @secure
   */
  performersList = (params: RequestParams = {}) =>
    this.request<Performer[], any>({
      path: `/performers/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags performers
   * @name PerformersCreate
   * @request POST:/performers/
   * @secure
   */
  performersCreate = (data: Performer, params: RequestParams = {}) =>
    this.request<Performer, any>({
      path: `/performers/`,
      method: "POST",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get the performer for the requesting user
   *
   * @tags performers
   * @name GetPerformerForUser
   * @request GET:/performers/get_performer_for_user/
   * @secure
   */
  getPerformerForUser = (params: RequestParams = {}) =>
    this.request<GetPerformerForUserResponse, any>({
      path: `/performers/get_performer_for_user/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags performers
   * @name PerformersRead
   * @request GET:/performers/{id}/
   * @secure
   */
  performersRead = (id: string, params: RequestParams = {}) =>
    this.request<Performer, any>({
      path: `/performers/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags performers
   * @name PerformersUpdate
   * @request PUT:/performers/{id}/
   * @secure
   */
  performersUpdate = (id: string, data: Performer, params: RequestParams = {}) =>
    this.request<Performer, any>({
      path: `/performers/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags performers
   * @name PerformersPartialUpdate
   * @request PATCH:/performers/{id}/
   * @secure
   */
  performersPartialUpdate = (id: string, data: Performer, params: RequestParams = {}) =>
    this.request<Performer, any>({
      path: `/performers/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Gets the links for a performer
   *
   * @tags performers
   * @name GetLinksForPerformer
   * @request GET:/performers/{id}/get_links_for_performer/
   * @secure
   */
  getLinksForPerformer = (id: string, params: RequestParams = {}) =>
    this.request<GetLinksForPerformerResponse, any>({
      path: `/performers/${id}/get_links_for_performer/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
