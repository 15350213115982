import { TextField, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import React from "react";
import { useNavigate } from "react-router";
import { StringParam, useQueryParam } from "use-query-params";
import { MonkeyButton } from "../components/Button";
import { MonkeyLoading } from "../components/Loading";
import { MonkeyTextField } from "../components/MonkeyTextField";
import {
  useGetGuestViaLinkQuery,
  useGetUserInfoQuery,
  useLogUserInMutation,
  useRegisterUserMutation,
} from "../endpoints";

const RegistrationDetails = ({
  overridePhone,
}: {
  overridePhone?: string;
}): React.ReactElement => {
  const navigate = useNavigate();
  const [attendPartyLink] = useQueryParam("inviteLink", StringParam);
  const [phoneNumber, setPhoneNumber] = React.useState<string>(
    overridePhone ?? ""
  );
  const [password, setPassword] = React.useState<string | undefined>(undefined);
  const [confirmed, setConfirmed] = React.useState<string | undefined>(
    undefined
  );
  const [firstName, setFirstName] = React.useState<string | undefined>(
    undefined
  );
  const [lastName, setLastName] = React.useState<string | undefined>(undefined);

  const [signUpError, setSignUpError] = React.useState<any | undefined>(
    undefined
  );
  console.log(attendPartyLink);

  const [registerUser] = useRegisterUserMutation();
  const [signUserIn] = useLogUserInMutation();

  const phoneNumberError = !!signUpError?.phone_number?.length
    ? signUpError?.phone_number[0]
    : undefined;

  const registerFunc = (): void => {
    if (!phoneNumber || !firstName || !lastName || !password) {
      return;
    }
    setSignUpError(undefined);
    registerUser({ firstName, lastName, phoneNumber, password })
      .unwrap()
      .then((_) => {
        signUserIn({ username: phoneNumber, password })
          .unwrap()
          .then((value) => {
            if (value.success) {
              const postFix = attendPartyLink
                ? `?inviteLink=${attendPartyLink}`
                : "";
              console.log(postFix);
              navigate(`/otp${postFix}`);
            } else {
              console.log("failed");
            }
          });
      })
      .catch((error) => {
        setSignUpError(error);
      });
  };

  return (
    <>
      <Typography>Sign up</Typography>
      <MuiTelInput
        value={phoneNumber}
        defaultCountry="US"
        disabled={!!overridePhone}
        onChange={(value: string, info: MuiTelInputInfo): void => {
          if (!!phoneNumberError) {
            setSignUpError((prev: any) => {
              return { ...prev, phone_number: [] };
            });
          }
          setPhoneNumber(info.numberValue || "");
        }}
        error={!!phoneNumberError}
        sx={{ width: "100%" }}
        helperText={phoneNumberError}
      />
      <TextField
        sx={{ width: "100%" }}
        label="Password"
        type="password"
        onChange={(e: any): void => setPassword(e.target.value)}
      />
      <MonkeyTextField
        fullWidth
        label="Confirm Password"
        type="password"
        errorMessage={
          confirmed !== password ? "Passwords do not match" : undefined
        }
        onChange={(e: any): void => setConfirmed(e.target.value)}
      />
      <TextField
        sx={{ width: "100%" }}
        label="First name"
        onChange={(e: any): void => setFirstName(e.target.value)}
      />
      <TextField
        sx={{ width: "100%" }}
        label="Last name"
        onChange={(e: any): void => setLastName(e.target.value)}
      />
      <MonkeyButton
        onClick={registerFunc}
        text="Sign up!"
        disabled={confirmed !== password}
      />
    </>
  );
};

// You can optionally pass in a link passthrough so when you complete this flow it will redirect
// to whatever came after
export const SignUp = (): React.ReactElement => {
  const navigate = useNavigate();
  // first deduce what context this is
  const [attendPartyLink] = useQueryParam("inviteLink", StringParam);
  const { data: guestData, isLoading: isGuestLoading } =
    useGetGuestViaLinkQuery(attendPartyLink ?? skipToken);

  const { data: userData, isLoading: isUserDataLoading } =
    useGetUserInfoQuery();

  // if this guest is already a user, TODO
  // if this guest only provided a phone number, we want to prefill phone number

  if (isGuestLoading || isUserDataLoading) {
    return <MonkeyLoading />;
  }

  // user already has an account
  if (userData) {
    const postFix = attendPartyLink ? `?inviteLink=${attendPartyLink}` : "";
    if (!userData.phone_number_verified) {
      navigate(`/otp${postFix}`);
    } else {
      navigate(postFix || "/");
    }

    return <MonkeyLoading />;
  }

  return (
    <RegistrationDetails
      overridePhone={guestData?.primary_contact?.phone_number || undefined}
    />
  );
};
