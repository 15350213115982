import { Divider, Stack } from "@mui/material";
import React from "react";

import { EventsAsGuestSection } from "./GuestSection";
import { EventsAsHostSection } from "./HostSection";

export const EventsSection = (): React.ReactElement => {
  return (
    <Stack spacing={2}>
      <EventsAsHostSection />
      <Divider
        orientation="horizontal"
        sx={{ color: "white", width: "100%" }}
      />
      <EventsAsGuestSection />
    </Stack>
  );
};
