import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useGetUserInfoQuery, useIsLoggedInQuery } from "../endpoints";

import { useNavigate } from "react-router";
import { OTPPage } from "../AccountManagement/OtpPage";
import { MonkeyLoading } from "../components/Loading";
import { ConnectionsSection } from "./ConnectionsSection";
import { EventsSection } from "./EventsSection";

export const UnloggedHome = (): React.ReactElement => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
    >
      <Typography variant="h3" component="h2">
        Welcome to EventMonkey
      </Typography>
    </Box>
  );
};

export const HomeWrapper = (): React.ReactElement => {
  const { data: isLoggedIn, isFetching: isUserFetching } = useIsLoggedInQuery();
  const { data: userData, isLoading: isUserDataLoading } =
    useGetUserInfoQuery();

  if (isUserFetching || isUserDataLoading) {
    return <MonkeyLoading />;
  }
  if (isLoggedIn && !userData?.phone_number_verified) {
    return <OTPPage />;
  }

  if (isLoggedIn) {
    return <Home />;
  }

  return <UnloggedHome />;
};

const Home = (): React.ReactElement => {
  const { data: userData, isLoading: isUserDataLoading } =
    useGetUserInfoQuery();
  const navigate = useNavigate();
  const userText = userData?.first_name ? `, ${userData.first_name}` : "";

  if (isUserDataLoading) {
    return <MonkeyLoading />;
  }

  if (!userData?.phone_number_verified) {
    // should avoid getting here as we get into a complicated circuit
    navigate("/otp");
    return <MonkeyLoading />;
  }

  return (
    <Stack height="100%" width="100%" alignItems="center" textAlign="center">
      <Typography variant="h3" component="h2" sx={{ marginBottom: "50px" }}>
        Welcome to EventMonkey{userText}
      </Typography>
      <Stack
        direction="row"
        spacing={4}
        divider={
          <Divider
            orientation="vertical"
            sx={{ color: "white", height: "100%" }}
          />
        }
      >
        <EventsSection />
        <Stack
          direction="column"
          spacing={1}
          divider={
            <Divider
              orientation="horizontal"
              sx={{ color: "white", width: "100%" }}
            />
          }
        >
          {/* <PerformersSection /> */}
          <ConnectionsSection />
        </Stack>
      </Stack>
    </Stack>
  );
};
