import { z } from "zod";

export interface MinMax {
  min?: number | null | undefined;
  max?: number | null | undefined;
}

export const minMaxFormSchema = z
  .object({
    min: z.optional(z.number().nullable()),
    max: z.optional(z.number().nullable()),
  })
  .refine(
    (data: any) => {
      if (!data.min && !data.max) {
        return false;
      }
      return true;
    },
    {
      path: ["max"],
      message: "You must specify both minimuma and maximum",
    }
  )
  .refine(
    (data: any) => {
      return !data.min || data.min < data.max;
    },
    {
      path: ["max"],
      message: "Minimum must be less than or equal to maximum",
    }
  );
