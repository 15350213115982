import React from "react";

import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { MonkeyButton } from "../components/Button";
import {
  eventMonkeyApi,
  useIsLoggedInQuery,
  useLogUserOutMutation,
} from "../endpoints";

export const LogOutButton = (): React.ReactElement => {
  const dispatch = useDispatch();
  const [logOut] = useLogUserOutMutation();
  const navigate = useNavigate();
  const { data: isLoggedIn } = useIsLoggedInQuery();
  const location = useLocation();

  // Access the current pathname from location
  const currentPath = location.pathname;
  const isSignInPage = currentPath.includes("sign-in");
  if (isSignInPage) {
    return <></>;
  }
  if (!isLoggedIn) {
    return (
      <MonkeyButton
        onClick={(): void => {
          navigate("/sign-in");
        }}
        text={"Sign in/Register"}
      />
    );
  }
  return (
    <MonkeyButton
      onClick={(): void => {
        logOut();
        dispatch(eventMonkeyApi.util.resetApiState());
        navigate("");
      }}
      text={"Log out"}
    />
  );
};
