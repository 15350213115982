import { TextField } from "@mui/material";
import React from "react";

export enum NumberTypes {
  INTEGER = "INTEGER",
  FLOAT = "FLOAT",
}

interface NumberInputProps {
  name?: string;
  value?: number | undefined | "" | null;
  initialValue?: number | undefined | null;
  onChange?: (newVal: number | undefined) => void;
  label?: string;
  type?: NumberTypes;
  error?: boolean; // if you provide errorMessage, you don't need to provide error
  errorMessage?: string;
  fullWidth?: boolean;
}
export const NumberInput = ({
  name,
  value,
  initialValue,
  onChange,
  label,
  type = NumberTypes.INTEGER,
  error,
  fullWidth,
  errorMessage,
}: NumberInputProps): React.ReactElement => {
  return (
    <TextField
      label={label}
      fullWidth={fullWidth}
      value={value}
      defaultValue={initialValue}
      name={name}
      onChange={(event): void => {
        if (event?.target.value === undefined || event.target.value === "") {
          return;
        }
        let newVal;
        if (type === NumberTypes.FLOAT) {
          newVal = parseFloat(event.target.value);
        } else {
          newVal = parseInt(event.target.value);
        }
        if (Number.isNaN(newVal)) {
          return;
        }
        onChange?.(newVal);
      }}
      inputProps={{
        pattern: "[0-9].*", // For mobile browsers that support input validation
        inputMode: "numeric", // For better virtual keyboards on mobile devices
      }}
      error={error || !!errorMessage}
      helperText={errorMessage}
    />
  );
};
