import React from "react";

import { Stack, Typography } from "@mui/material";
import { useMapIsLoaded } from "../hooks";
import { DEFAULT_CENTER, LocType, RoughLocSchema } from "../types";
import { MapComponent, WidthAndHeight } from "./MapLocSelect";

const DEFAULT_CONTAINER_STYLE = {
  width: "700px",
  height: "500px",
};

const DEFAULT_RADIUS = 3000;

export const RoughLocationSelect = ({
  initLoc,
  initRadius,
  editable = true,
  setRoughLoc,
  containerStyle,
}: {
  initLoc?: LocType;
  initRadius?: number;
  editable?: boolean;
  setRoughLoc?: (newLoc: RoughLocSchema | undefined) => void;
  containerStyle?: WidthAndHeight;
}): React.ReactElement => {
  // state
  const [currLoc, setCurrLoc] = React.useState<LocType | undefined>(initLoc);
  const [currCircle, setCurrCircle] = React.useState<
    google.maps.Circle | undefined
  >(undefined);

  // initial load
  const isLoaded = useMapIsLoaded();

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(setCurrLocWrapper, errorCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // callbacks
  // if the user denies location access, just set Berkeley
  const errorCallback = (error: GeolocationPositionError): void => {
    if (!currCircle) {
      return;
    }
    currCircle.setCenter(DEFAULT_CENTER);
    setCurrLoc(DEFAULT_CENTER);
    handleRadiusChange(
      DEFAULT_CENTER.lat,
      DEFAULT_CENTER.lng,
      currCircle?.getRadius() ?? DEFAULT_RADIUS
    );
  };

  // if they accept location access, set their location
  const setCurrLocWrapper = (location: GeolocationPosition): void => {
    if (initLoc) {
      // dont use their location if we provided an init loc
      return;
    }
    const newCenter = {
      lat: location.coords.latitude,
      lng: location.coords.longitude,
    };
    setCurrLoc(newCenter);

    handleRadiusChange(
      newCenter.lat,
      newCenter.lng,
      currCircle?.getRadius() ?? DEFAULT_RADIUS
    );
    if (!currCircle) {
      return;
    }
    currCircle.setCenter(newCenter);
  };

  const handleRadiusChange = React.useCallback(
    (
      lat: number | undefined,
      lon: number | undefined,
      radius: number | undefined
    ) => {
      if (
        !setRoughLoc ||
        lat === undefined ||
        lon === undefined ||
        radius === undefined
      ) {
        return;
      }
      setRoughLoc({
        lat: lat,
        lon: lon,
        radius: radius,
      });
    },
    [setRoughLoc]
  );

  const onLoad = React.useCallback(
    function callback(map: any) {
      if (!currLoc) {
        return;
      }

      const circle = new window.google.maps.Circle();
      circle.setCenter(currLoc);
      circle.setRadius(initRadius ?? DEFAULT_RADIUS);
      circle.setOptions({
        editable: editable,
        fillColor: "#ffd4d4",
      });

      const listener = (): void => {
        const newCenter = circle?.getCenter();
        handleRadiusChange(
          newCenter?.lat(),
          newCenter?.lng(),
          circle?.getRadius()
        );
      };
      circle.addListener("radius_changed", listener);
      circle.addListener("center_changed", listener);

      circle.setMap(map);
      setCurrCircle(circle);
      map.setZoom(12);
    },
    [currLoc, editable, handleRadiusChange, initRadius]
  );

  const onUnmount = React.useCallback(function callback(map: any) {
    setCurrCircle(undefined);
  }, []);

  // final components

  return (
    <Stack spacing={1} alignItems="center">
      {editable && (
        <Typography component="span" variant="body1">
          Where will you throw your party?
        </Typography>
      )}
      <Typography component="span" variant="body2">
        We will try to find a venue within this area
      </Typography>
      <MapComponent
        containerStyle={containerStyle ?? DEFAULT_CONTAINER_STYLE}
        isLoaded={isLoaded}
        mapCenter={currLoc}
        onLoad={onLoad}
        onUnmount={onUnmount}
      />
    </Stack>
  );
};
