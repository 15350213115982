import { Box, Stack, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { File } from "../apiClient/data-contracts";
import { useGetLocationQuery, useListPhotosQuery } from "../endpoints";
import { getDisplayForMinMaxObj } from "../helpers/utils";

export const LocationView = (): React.ReactElement => {
  const { locationId } = useParams();
  const { data: locationData, isFetching } = useGetLocationQuery(
    locationId ?? skipToken
  );
  const { data: photosToShow, isLoading: isPhotosLoading } = useListPhotosQuery(
    locationId ? { event_location_id: locationId } : skipToken
  );

  // Haven't found locationId then we're still loading
  if (!locationId || isFetching || isPhotosLoading) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }

  // No location data found then there was an error
  if (!locationData) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <Stack
      spacing={1}
      border="1px solid lightgrey"
      width="100%"
      padding="16px"
      borderRadius="4px"
      alignItems="center"
    >
      <Typography component="span" variant="body1">
        {`Location: ${locationData.address}`}
      </Typography>
      {!!locationData.website && (
        <Typography component="span" variant="body1">
          <Link to={locationData.website} style={{ color: "#FFFFFF" }}>
            Website
          </Link>
        </Typography>
      )}
      {!!locationData.capacity && (
        <Typography component="span" variant="body1">
          {`Capacity: ${getDisplayForMinMaxObj({
            minMaxObj: locationData.capacity,
          })}`}
        </Typography>
      )}
      {!!locationData.price_range && (
        <Typography component="span" variant="body1">
          {`Price Range: ${getDisplayForMinMaxObj({
            minMaxObj: locationData.price_range,
            prefix: "$",
          })}`}
        </Typography>
      )}
      {!!photosToShow?.length && (
        <Stack
          spacing={1}
          paddingTop="8px"
          maxWidth="500px"
          maxHeight="500px"
          overflow="auto"
          alignItems="center"
        >
          {photosToShow
            .filter((photo: File) => !!photo.url)
            .map((photo: File) => {
              return (
                <Box maxHeight="350px" key={photo.id ?? photo.url}>
                  <img
                    style={{ maxHeight: "350px", height: "100%" }}
                    src={photo.url!}
                    alt={photo.name}
                  />
                </Box>
              );
            })}
        </Stack>
      )}
    </Stack>
  );
};
