import { z } from "zod";

import { minMaxFormSchema } from "../../logic/types";

const autoFlowPackageSchema = z.object({
  name: z.string(),
  id: z.string(),
  price: minMaxFormSchema,
  location: z.string().optional(), // id of the place
  performer: z.string().optional(), // id of the performer
});

export const autoFlowResponseSchema = z.object({
  packages: z.array(autoFlowPackageSchema),
});
export type AutoFlowResponseZod = z.infer<typeof autoFlowResponseSchema>;
export type AutoFlowPackageZod = z.infer<typeof autoFlowPackageSchema>;
