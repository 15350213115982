import { z } from "zod";

export const DEFAULT_CENTER = {
  lat: 37.87,
  lng: -122.27,
};

export interface LocType {
  lat: number;
  lng: number;
}

export const roughLocSchema = z.object({
  lat: z.number(),
  lon: z.number(),
  radius: z.number(),
});

export type RoughLocSchema = z.infer<typeof roughLocSchema>;

export const zCoordinates = z.object({
  lat: z.number(),
  lon: z.number(),
});

export type MonkeyCoordinates = z.infer<typeof zCoordinates>;
