import { Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import theme from "../../theme";
import { AutoEventCreateForm } from "./requestTypes";

const RegisterableNumberInput = ({
  register,
  name,
  label,
  error,
}: {
  name: string;
  label: string;
  register: UseFormRegister<AutoEventCreateForm>;
  error: boolean;
}) => {
  return (
    <TextField
      label={label}
      type="number"
      {...register(name as any, {
        setValueAs: (value) => Number(value),
      })}
      InputProps={{ endAdornment: <></> }}
      inputProps={{
        pattern: "[0-9].*", // For mobile browsers that support input validation
        inputMode: "numeric", // For better virtual keyboards on mobile devices
      }}
      sx={{
        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "input[type=number]": {
          MozAppearance: "textfield",
        },
      }}
      error={error}
    />
  );
};

export const ExtraSpecPage = ({
  setEventTime,
  errors,
  register,
}: {
  setEventTime: (arg: Date) => void;
  errors?: FieldErrors<AutoEventCreateForm>;
  register: UseFormRegister<AutoEventCreateForm>;
}): React.ReactElement => {
  // errors
  const budgetError =
    errors?.budget?.message ||
    errors?.budget?.root?.message ||
    errors?.budget?.min?.message ||
    errors?.budget?.max?.message;

  const attendeesError =
    errors?.attendees?.message ||
    errors?.attendees?.root?.message ||
    errors?.attendees?.min?.message ||
    errors?.attendees?.max?.message;

  const startTimeError = !!errors?.eventTime;

  const {
    onBlur: eventTimeOnBlur,
    ref: eventTimeRef,
    name: eventTimeName,
  } = register("eventTime");

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <Stack>
          <RegisterableNumberInput
            register={register}
            name="budget.max"
            label="Max budget"
            error={!!budgetError}
          />
          {budgetError && (
            <Typography
              component="span"
              variant="body2"
              color={theme.palette.error.main}
            >
              Required
            </Typography>
          )}
        </Stack>
        <Stack>
          <RegisterableNumberInput
            register={register}
            name="attendees.max"
            label="Max attendees"
            error={!!attendeesError}
          />
          {attendeesError && (
            <Typography
              component="span"
              variant="body2"
              color={theme.palette.error.main}
            >
              Required
            </Typography>
          )}
        </Stack>
      </Stack>
      <TextField
        {...register("description")}
        multiline
        label="Description"
        maxRows={5}
      />
      <Typography component="span" variant="body1">
        What day are you planning on hosting this event?
      </Typography>
      <DateTimePicker
        sx={{ width: "100%" }}
        disablePast
        label="Doors Open"
        onChange={(time: Date | null): void => {
          if (!time) {
            return;
          }
          setEventTime(time);
        }}
      />
      {/* TODO Put this back (its just buggy because it jumps around) */}
      {/* <Box border="1px solid red">
        <DateTimePicker
          // sx={{ width: "100%" }}
          disablePast
          label="Doors Open"
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                //   sx={{ width: "100%" }}
                error={startTimeError}
                //   ref={eventTimeRef}
                name={eventTimeName}
                onBlur={eventTimeOnBlur}
                helperText={
                  startTimeError
                    ? "Please indicate what (future) time the event will begin"
                    : undefined
                }
              />
            ),
          }}
          onChange={(time: Date | null): void => {
            if (!time) {
              return;
            }
            setEventTime(time);
          }}
        />
      </Box> */}
    </Stack>
  );
};
