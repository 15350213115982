import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { FieldErrors } from "react-hook-form";
import {
  PerformerSpecialty,
  PerformerType,
} from "../../apiClient/data-contracts";
import {
  useListPerformerSpecialtiesQuery,
  useListPerformerTypesQuery,
} from "../../endpoints";
import { AutoEventCreateForm, PerformerOption } from "./requestTypes";

export const PerformerSelect = ({
  performers,
  setPerformers,
  errors,
}: {
  performers: PerformerOption | undefined;
  setPerformers: (performers: PerformerOption) => void;
  errors?: FieldErrors<AutoEventCreateForm>;
}): React.ReactElement => {
  // initial state
  const typeVal = performers?.performerType;
  const specialtiesVal = performers?.specialty;

  // RTKQ
  const { data: performerSpecialties, isLoading: isSpecialtiesLoading } =
    useListPerformerSpecialtiesQuery();
  const { data: performerTypes, isLoading: isTypesLoading } =
    useListPerformerTypesQuery();

  // callbacks
  const setType = (newType: string | undefined): void => {
    setPerformers({
      specialty: performers?.specialty,
      performerType: newType || undefined,
    });
  };
  const setSpecialties = (newSpecialties: string[] | undefined): void => {
    setPerformers({
      specialty: newSpecialties,
      performerType: performers?.performerType,
    });
  };

  if (isSpecialtiesLoading || isTypesLoading) {
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <Typography component="span" variant="body1">
          Loading...
        </Typography>
      </Box>
    );
  }

  if (!performerSpecialties || !performerTypes) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <Stack width="100%" alignItems="center" spacing={2}>
      <Typography component="span" variant="body1">
        What kind of performers are you looking for?
      </Typography>
      <Autocomplete
        options={performerTypes}
        value={
          performerTypes.find(
            (currType) => !!currType.id && typeVal === currType.id
          ) ?? null
        }
        sx={{ width: "100%" }}
        onChange={(_: any, newValue: PerformerType | null): void => {
          if (!newValue || !newValue.id) {
            return;
          }
          setSpecialties([]);
          setType(newValue.id);
        }}
        // @ts-ignore
        getOptionLabel={(option: PerformerType) => {
          if (typeof option === "string") {
            return option;
          }
          return option.name;
        }}
        renderInput={(params) => <TextField {...params} label="Type" />}
      />
      <Autocomplete
        disabled={!typeVal}
        options={performerSpecialties.filter(
          (specialty: PerformerSpecialty): boolean => {
            return (
              !!specialty.id &&
              !!performerTypes
                .find((currType: PerformerType): any => {
                  return currType.id === typeVal;
                })
                ?.specialties.includes(specialty.id)
            );
          }
        )}
        value={performerSpecialties.filter(
          (specialty) =>
            !!specialty.id && specialtiesVal?.includes(specialty.id)
        )}
        sx={{ width: "100%" }}
        onChange={(_: any, newValue: (string | PerformerSpecialty)[]): void => {
          setSpecialties(
            newValue.map((thingy: string | PerformerSpecialty): string => {
              if (typeof thingy === "string") {
                return thingy;
              } else {
                return thingy.id!;
              }
            })
          );
        }}
        multiple
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.name;
        }}
        renderInput={(params) => <TextField {...params} label="Specialty" />}
      />
      {!!errors?.performersOptions && (
        <Typography component="span" variant="body1" color="error">
          Please fill out both fields
        </Typography>
      )}
    </Stack>
  );
};
