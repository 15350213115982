import { Tooltip } from "@mui/material";
import React from "react";

interface MonkeyTooltipProps {
  children: React.ReactElement;
  offsetX?: number;
  offsetY?: number;
  title: string;
  sx?: any;
  placement?: "top" | "right" | "left" | "bottom"; // too lazy to get mui types for this - todo
}

export const MonkeyTooltip: React.FC<MonkeyTooltipProps> = ({
  children,
  title,
  sx,
  placement = "top",
  offsetX = 0,
  offsetY = 0,
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      sx={sx}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [offsetY, offsetX],
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );
};
