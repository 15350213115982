import {
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { skipToken } from "@reduxjs/toolkit/query";
import { parseISO } from "date-fns";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocationAutocomplete } from "../Locations/components/LocationAutocomplete";
import { Event, EventLocation, Performer } from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import { MonkeyTextField } from "../components/MonkeyTextField";
import { PerformerAutocomplete } from "../components/Performer/PerformerAutocomplete";
import {
  useCreateEventMutation,
  useGetEventQuery,
  useGetOrCreateLocationMutation,
  useGetUserInfoQuery,
  useListLocationsQuery,
  useListPerformersQuery,
  useModifyEventMutation,
} from "../endpoints";
import { useIsSmallScreen } from "../logic/hooks";

export const EventEditorWrapper = (): React.ReactElement => {
  const { eventId } = useParams();
  const {
    data: eventData,
    isLoading: isEventLoading,
    isError,
  } = useGetEventQuery(eventId ? eventId : skipToken);
  // TODO: get user

  const { data: userInfo, isLoading: isUserLoading } = useGetUserInfoQuery();

  if (isEventLoading || isUserLoading) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }
  if (!userInfo?.id) {
    return (
      <Typography component="span" variant="body1">
        Error
      </Typography>
    );
  }

  if (eventData && !eventData.hosts?.includes(userInfo.id)) {
    return (
      <Typography component="span" variant="body1">
        You cannot modify this event as you are not a host...
      </Typography>
    );
  }

  return <EventEditor eventData={eventData} currUserId={userInfo.id} />;
};
interface EventEditorProps {
  eventData?: Event;
  currUserId: string;
}
export const EventEditor = ({
  eventData,
  currUserId,
}: EventEditorProps): React.ReactElement => {
  // state
  const [eventName, setEventName] = React.useState<string | undefined>(
    eventData?.name
  );
  const [startTime, setStartTime] = React.useState<string | null | undefined>(
    eventData?.start
  );
  const [locationId, setLocationId] = React.useState<string | undefined>(
    eventData?.location || undefined
  );
  const [performerId, setPerformerId] = React.useState<string | undefined>(
    eventData?.performers?.[0] || undefined
  );
  const [description, setDescription] = React.useState<string | undefined>(
    eventData?.description ?? ""
  );
  const [locationIsCustom, setLocationIsCustom] =
    React.useState<boolean>(false);

  const [locationError, setLocationError] = React.useState<string | undefined>(
    undefined
  );

  const [eventVisibility, setEventVisibility] = React.useState<
    "INVITED_GUESTS_ONLY" | "ANYONE" | "HOSTS_ONLY" | "PLUS_ONE"
  >(eventData?.visibility ?? "INVITED_GUESTS_ONLY");

  // RTK/Hooks
  const { data: userData, isLoading: isLoadingUser } = useGetUserInfoQuery();
  const { data: performerOptions, isLoading: isPerformersLoading } =
    useListPerformersQuery();
  const { data: locationOptions, isLoading: isLocationsLoading } =
    useListLocationsQuery();

  const navigate = useNavigate();
  const [createEvent] = useCreateEventMutation();
  const [modifyEvent] = useModifyEventMutation();
  const [getOrCreateLocation] = useGetOrCreateLocationMutation();
  const isSmallScreen = useIsSmallScreen();

  // derived
  const filteredPerformerOptions = React.useMemo(() => {
    if (!userData?.favorite_performers?.length) {
      return [];
    }
    return performerOptions?.filter(
      (performer: Performer) =>
        performer.id && userData?.favorite_performers?.includes(performer.id)
    );
  }, [performerOptions, userData?.favorite_performers]);

  // Callbacks
  const createEventWrapper = (createdLocationId?: string): void => {
    if (!eventName || !startTime) {
      return;
    }
    createEvent({
      name: eventName,
      start: startTime,
      performers: performerId ? [performerId] : [],
      location: createdLocationId ?? locationId,
      visibility: eventVisibility,
      guests: [],
      description: description,
      hosts: [currUserId], // TODO: Do this one
    })
      .unwrap()
      .then((result) => {
        navigate(`/event/view/${result.id}`);
      });
  };
  const modifyEventWrapper = (createdLocationId?: string): void => {
    if (!eventData?.id || !eventName || !startTime) {
      return;
    }

    modifyEvent({
      id: eventData?.id,
      data: {
        name: eventName,
        start: startTime,
        performers: performerId ? [performerId] : [],
        location: createdLocationId ?? locationId,
        visibility: eventVisibility,
        description: description,
        guests: eventData?.guests ?? [], // TODO: do this one
        hosts: eventData?.hosts ?? [currUserId], // TODO: Do this one
      },
    })
      .unwrap()
      .then((_) => {
        navigate("/");
      });
  };

  const locationOnChange = (
    event: any,
    newValue: EventLocation | string | null
  ): void => {
    setLocationError(undefined);
    if (!newValue) {
      return;
    }
    if (typeof newValue === "string") {
      setLocationId(newValue);
      setLocationIsCustom(true);
      return;
    }
    setLocationIsCustom(false);
    setLocationId(newValue.id);
  };

  const NameAndDate = (
    <>
      <TextField
        sx={{ width: "100%" }}
        label="Name"
        defaultValue={eventName}
        onChange={(event: any): void => {
          setEventName(event?.target.value);
        }}
      />
      <DateTimePicker
        sx={{ width: isSmallScreen ? "100%" : "350px" }}
        disablePast
        // @ts-ignore
        defaultValue={startTime ? parseISO(startTime) : null}
        label="Doors Open"
        onChange={(time: string | null): void => {
          setStartTime(time);
        }}
      />
    </>
  );

  const LocationAndVisibility = (
    <>
      {isLocationsLoading ? (
        <Skeleton width="100%" height="45px" />
      ) : (
        <LocationAutocomplete
          locationOptions={locationOptions ?? []} // TODO Ensure the ?? is ok
          locationId={locationId}
          onChange={locationOnChange}
          locationError={locationError}
          setLocationError={setLocationError}
        />
      )}
      <TextField
        select
        sx={{ width: isSmallScreen ? "100%" : "350px" }}
        label="Event Visibility"
        value={eventVisibility}
        onChange={(textFieldEvent: any): void => {
          setEventVisibility(textFieldEvent.target.value);
        }}
        variant="outlined"
      >
        <MenuItem value="ANYONE">Anyone</MenuItem>
        <MenuItem value="INVITED_GUESTS_ONLY">Invited Guests Only</MenuItem>
        <MenuItem value="HOSTS_ONLY">Hosts only</MenuItem>
        {/* TODO: Add +1 option */}
      </TextField>
    </>
  );

  const performerOnChange = (event: any, newValue: Performer | null) => {
    if (!newValue) {
      return;
    }
    setPerformerId(newValue.id);
  };

  const PerformerSelect = isPerformersLoading ? (
    <Skeleton width="100%" height="45px" />
  ) : (
    <PerformerAutocomplete
      options={filteredPerformerOptions ?? []}
      performerId={performerId}
      onChange={performerOnChange}
      labelOverride="Performers (favorites)"
    />
  );

  const DescriptionField = (
    <MonkeyTextField
      fullWidth
      label="Description"
      multiline
      value={description}
      onChange={(event) => setDescription(event.target.value ?? "")}
    />
  );

  const CreateButton = (
    <MonkeyButton
      text={eventData?.id ? "Save" : "Create event"}
      disabled={!eventName || !startTime || !locationId || isLoadingUser}
      onClick={async (): Promise<void> => {
        // first create the location if its custom
        let createdLocationId = undefined;

        if (locationIsCustom && locationId) {
          const createdLocation = await getOrCreateLocation(locationId)
            .unwrap()
            .catch((error) => {
              setLocationError(
                error.message ?? "We couldn't find a match for this location"
              );
            });
          if (!createdLocation) {
            return;
          }
          createdLocationId = createdLocation.id;
          setLocationId(createdLocationId);
        }
        eventData?.id
          ? modifyEventWrapper(createdLocationId)
          : createEventWrapper(createdLocationId);
      }}
    />
  );

  if (isSmallScreen) {
    return (
      <Stack spacing={2} width="100%" alignItems="center">
        {NameAndDate}
        {LocationAndVisibility}
        {PerformerSelect}
        {DescriptionField}
        {CreateButton}
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="row" spacing={1} width="100%">
        {NameAndDate}
      </Stack>
      <Stack direction="row" spacing={1} width="100%">
        {LocationAndVisibility}
      </Stack>
      {PerformerSelect}
      {DescriptionField}
      {CreateButton}
    </>
  );
};
