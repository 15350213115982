import { z } from "zod";

export const contactInfoSchema = z
  .object({
    phoneNumber: z.string().optional(),
    link: z.string().url().optional(),
    contactName: z.string().optional(),
  })
  .refine(
    (data: any) => {
      if (!data.phoneNumber && !data.link && !data.contactName) {
        return false;
      }
      return true;
    },
    {
      message: "You must specify at least one field for contact",
    }
  );
