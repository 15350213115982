import { TextField } from "@mui/material";
import React from "react";
import darkTheme, { lightTheme } from "../theme";

interface MonkeyTextFieldProps {
  // you must either provide `value` or `name` and `onChange` (ie either fully controlled via a form, or manually controlled)
  value?: string;
  defaultValue?: string;
  name?: string;
  onChange?: (event: any) => void;
  onKeyDown?: (event: any) => void;
  label?: string;
  fullWidth?: boolean;
  placeholder?: string;
  mode?: "dark" | "light";
  multiline?: boolean;
  errorMessage?: string;
  type?: string;
  disabled?: boolean;
}
export const MonkeyTextField = ({
  name,
  value,
  defaultValue,
  onChange,
  onKeyDown,
  label,
  fullWidth,
  placeholder,
  mode = "dark",
  type,
  multiline,
  errorMessage,
  disabled,
}: MonkeyTextFieldProps): React.ReactElement => {
  // TODO: figure out best way to write this: we want black outline on white background and vice versa
  const themeToUse = mode === "dark" ? lightTheme : darkTheme;

  return (
    <TextField
      defaultValue={defaultValue}
      value={value}
      name={name}
      type={type}
      fullWidth={fullWidth}
      onChange={onChange}
      onKeyDown={onKeyDown}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      multiline={multiline}
      maxRows={multiline ? 3 : undefined}
      sx={{
        "& .MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderColor: themeToUse.palette.background.default, // Ensure the hover color is the same
          },
        },
      }}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};
