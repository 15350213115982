/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AttendPartyLink, BulkInvitePeopleRequest, Success, UseSignUpLinkRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class SignUpLinks<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Invite people to your party
   *
   * @tags sign-up-links
   * @name BulkInvitePeople
   * @request POST:/sign-up-links/bulk_invite_people/
   * @secure
   */
  bulkInvitePeople = (data: BulkInvitePeopleRequest, params: RequestParams = {}) =>
    this.request<Success, any>({
      path: `/sign-up-links/bulk_invite_people/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags sign-up-links
   * @name SignUpLinksRead
   * @request GET:/sign-up-links/{id}/
   * @secure
   */
  signUpLinksRead = (id: string, params: RequestParams = {}) =>
    this.request<AttendPartyLink, any>({
      path: `/sign-up-links/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Use a sign up link for a party
   *
   * @tags sign-up-links
   * @name UseSignUpLink
   * @request POST:/sign-up-links/{id}/use_sign_up_link/
   * @secure
   */
  useSignUpLink = (id: string, data: UseSignUpLinkRequest, params: RequestParams = {}) =>
    this.request<AttendPartyLink, any>({
      path: `/sign-up-links/${id}/use_sign_up_link/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
