import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { ViewAuto } from "../Events/AutoCreate/ViewAuto";

import {
  AutoFlowPackageZod,
  AutoFlowResponseZod,
  autoFlowResponseSchema,
} from "../Events/AutoCreate/responseTypes";
import { LocationAutocomplete } from "../Locations/components/LocationAutocomplete";
import { EventLocation, Performer } from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import { MonkeyTextField } from "../components/MonkeyTextField";
import { NumberInput } from "../components/NumberInput";
import { PerformerAutocomplete } from "../components/Performer/PerformerAutocomplete";
import { MonkeyTooltip } from "../components/Tooltip";
import {
  useListLocationsQuery,
  useListPerformersQuery,
  useUpdateAutoFlowRequestMutation,
} from "../endpoints";
import { useIsSmallScreen } from "../logic/hooks";

const AutoFlowPackageBuilder = ({
  pkg,
  setValue,
  idx,
  locationOptions,
  performerOptions,
  deletePackage,
}: {
  pkg: AutoFlowPackageZod;
  idx: number;
  setValue: (pkg: AutoFlowPackageZod) => void;
  locationOptions: EventLocation[];
  performerOptions: Performer[];
  deletePackage: (idx: number) => void;
}): React.ReactElement => {
  const displayName = pkg.name ?? `Package ${idx + 1}`;
  return (
    <Stack width="100%" spacing={2} alignItems="center" paddingBottom="30px">
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography component="span" variant="h5">
          {displayName}
        </Typography>
        <MonkeyButton text="Delete" onClick={(): void => deletePackage(idx)} />
      </Stack>
      <MonkeyTextField
        fullWidth
        value={pkg.name ?? ""}
        label="Package name"
        onChange={(event) => {
          setValue({ ...pkg, name: event.target.value });
        }}
      />
      <NumberInput
        fullWidth
        value={pkg.price?.min ?? ""}
        label="Minimum price"
        onChange={(newNum) => {
          setValue({ ...pkg, price: { ...pkg.price, min: newNum } });
        }}
      />
      <NumberInput
        fullWidth
        value={pkg.price?.max ?? ""}
        label="Maximum price"
        onChange={(newNum) => {
          setValue({ ...pkg, price: { ...pkg.price, max: newNum } });
        }}
      />
      <LocationAutocomplete
        locationId={pkg.location}
        locationOptions={locationOptions}
        freeSolo={false}
        onChange={(event: any, newValue: EventLocation | null) => {
          setValue({
            ...pkg,
            location: newValue ? newValue.id : undefined,
          });
        }}
      />
      <PerformerAutocomplete
        options={performerOptions ?? []}
        performerId={pkg.performer}
        onChange={(event: any, newValue: Performer | null) => {
          setValue({
            ...pkg,
            performer: newValue ? newValue.id : undefined,
          });
        }}
      />
    </Stack>
  );
};

const AutoFlowResponder = ({
  control,
  setValue,
  formState,

  packagesVal,
}: {
  control: any;
  setValue: any;
  formState: any;
  packagesVal: AutoFlowPackageZod[];
}): React.ReactElement => {
  /////////
  // RTK //
  /////////
  const { currentData: performerOptions } = useListPerformersQuery();
  const { currentData: locationOptions } = useListLocationsQuery();

  const addPackage = () => {
    setValue("packages", [...packagesVal, { id: uuidv4().toString() }]);
  };

  const deletePackage = (idxToRemove: number) => {
    const copiedPackages = [...packagesVal];
    copiedPackages.splice(idxToRemove, 1);
    setValue("packages", copiedPackages);
  };

  return (
    <Stack
      width="100%"
      padding="8px"
      spacing={1}
      alignItems="center"
      maxHeight="75vh"
      overflow="auto"
    >
      {packagesVal.map((pkg: AutoFlowPackageZod, idx: number) => {
        // special setval which will only update the value of the package being passed into the prop
        const setVal = (currPkg: AutoFlowPackageZod) => {
          const newPackages = [...packagesVal];
          newPackages[idx] = currPkg;
          setValue("packages", newPackages, { shouldValidate: true });
        };
        return (
          <AutoFlowPackageBuilder
            pkg={pkg}
            key={idx}
            idx={idx}
            setValue={setVal}
            locationOptions={locationOptions ?? []}
            performerOptions={performerOptions ?? []}
            deletePackage={deletePackage}
          />
        );
      })}
      <MonkeyTooltip title="Add a package">
        <div>
          <MonkeyButton
            border
            onClick={addPackage}
            text="Add a package"
            width="150px"
          />
        </div>
      </MonkeyTooltip>
      <MonkeyButton type="submit" text="Submit" />
    </Stack>
  );
};

export const AutoFlowResponderWrapper = (): React.ReactElement => {
  const { requestId } = useParams();
  const [showParams, setShowParams] = React.useState(true);
  const [updateAutoFlowRequest] = useUpdateAutoFlowRequestMutation();

  ///////////////
  // form init //
  ///////////////

  const { control, setValue, handleSubmit, formState } =
    useForm<AutoFlowResponseZod>({
      resolver: zodResolver(autoFlowResponseSchema),
      defaultValues: {
        packages: [{ id: uuidv4().toString() }],
      },
    });

  ///////////////////////
  // form registration //
  ///////////////////////
  const packagesController = useController({
    name: "packages",
    control,
  }).field;

  //////////////////////
  // state management //
  //////////////////////
  const packagesVal = packagesController.value;

  const isSmallScreen = useIsSmallScreen();

  const onSubmit = async (values: AutoFlowResponseZod): Promise<void> => {
    if (!requestId) {
      return;
    }

    const updatedParams = {
      packages: values.packages.map((pkg: AutoFlowPackageZod) => {
        return {
          id: pkg.id,
          name: pkg.name,
          price: pkg.price,
          location_id: pkg.location,
          performer_id: pkg.performer,
        };
      }),
    };

    updateAutoFlowRequest({
      id: requestId,
      response_params_json: updatedParams,
    })
      .unwrap()
      .then(() => toast.success("Success", { duration: 1000 }))
      .catch(() => toast.error("Failed", { duration: 2000 }));
  };

  const CustomerRequest = (
    <Stack
      spacing={2}
      alignItems="center"
      width={showParams ? "100%" : undefined}
    >
      {showParams && (
        <Typography component="span" variant="h3">
          Customer request
        </Typography>
      )}
      <Stack
        direction="row"
        height="100%"
        alignItems="center"
        width="100%"
        spacing={2}
      >
        {showParams && (
          <Box width="100%">
            <ViewAuto fromAdminView />
          </Box>
        )}
        <MonkeyButton
          height="100%"
          border
          text={showParams ? "<" : ">"}
          onClick={(): void => {
            setShowParams((old) => !old);
          }}
        />
      </Stack>
    </Stack>
  );

  const MonkeyResponse = (
    <Stack spacing={2} alignItems="center" width="100%">
      <Typography component="span" variant="h3">
        EventMonkey Response
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <AutoFlowResponder
          control={control}
          setValue={setValue}
          formState={formState}
          packagesVal={packagesVal}
        />
      </form>
    </Stack>
  );

  if (isSmallScreen) {
    return (
      <Stack spacing={1}>
        {MonkeyResponse}
        {CustomerRequest}
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      spacing={2}
    >
      <Toaster position="top-center" />
      {CustomerRequest}
      {!showParams && MonkeyResponse}
    </Stack>
  );
};
