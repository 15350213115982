/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GuestAtEvent,
  MakeFriendsRequest,
  MakeFriendsResponse,
  MonkeyUser,
  OTPValidateRequest,
  UpdateMonkeyUser,
  UpdatePasswordRequest,
  UserRegisterResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Users<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags users
   * @name UsersList
   * @request GET:/users/
   * @secure
   */
  usersList = (params: RequestParams = {}) =>
    this.request<MonkeyUser[], any>({
      path: `/users/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags users
   * @name UsersCreate
   * @request POST:/users/
   * @secure
   */
  usersCreate = (data: MonkeyUser, params: RequestParams = {}) =>
    this.request<MonkeyUser, any>({
      path: `/users/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get a user's info
   *
   * @tags users
   * @name GetUser
   * @request GET:/users/get_user/
   * @secure
   */
  getUser = (params: RequestParams = {}) =>
    this.request<MonkeyUser, any>({
      path: `/users/get_user/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description List the events for a guest
   *
   * @tags users
   * @name ListEventsAsGuest
   * @request GET:/users/list_events_as_guest/
   * @secure
   */
  listEventsAsGuest = (params: RequestParams = {}) =>
    this.request<GuestAtEvent[], any>({
      path: `/users/list_events_as_guest/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get a user's info
   *
   * @tags users
   * @name ListFriends
   * @request GET:/users/list_friends/
   * @secure
   */
  listFriends = (params: RequestParams = {}) =>
    this.request<MonkeyUser[], any>({
      path: `/users/list_friends/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get or create a host for authed user
   *
   * @tags users
   * @name MakeFriends
   * @request POST:/users/make_friends/
   * @secure
   */
  makeFriends = (data: MakeFriendsRequest, params: RequestParams = {}) =>
    this.request<MakeFriendsResponse, any>({
      path: `/users/make_friends/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Update current users password
   *
   * @tags users
   * @name UpdatePassword
   * @request POST:/users/update_password/
   * @secure
   */
  updatePassword = (data: UpdatePasswordRequest, params: RequestParams = {}) =>
    this.request<MonkeyUser, any>({
      path: `/users/update_password/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Validate a user's phone via otp
   *
   * @tags users
   * @name ValidateUserViaOtp
   * @request POST:/users/validate_user_via_otp/
   * @secure
   */
  validateUserViaOtp = (data: OTPValidateRequest, params: RequestParams = {}) =>
    this.request<UserRegisterResponse, any>({
      path: `/users/validate_user_via_otp/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags users
   * @name UsersUpdate
   * @request PUT:/users/{id}/
   * @secure
   */
  usersUpdate = (id: string, data: UpdateMonkeyUser, params: RequestParams = {}) =>
    this.request<UpdateMonkeyUser, any>({
      path: `/users/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags users
   * @name UsersPartialUpdate
   * @request PATCH:/users/{id}/
   * @secure
   */
  usersPartialUpdate = (id: string, data: UpdateMonkeyUser, params: RequestParams = {}) =>
    this.request<UpdateMonkeyUser, any>({
      path: `/users/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
}
