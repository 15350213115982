import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      color: "white", // White text
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      color: "white",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 700,
      color: "white",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: "white",
    },
    h5: {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "white",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
      color: "white",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "rgba(255, 255, 255, 0.87)", // Off-white text
    },
    body2: {
      fontSize: "0.9rem",
      fontWeight: 400,
      color: "rgba(255, 255, 255, 0.87)",
    },
  },
  palette: {
    mode: "dark", // Enables dark mode
    primary: {
      main: "#2196f3", // Material blue
    },
    secondary: {
      main: "#FAF9F6", // off white
    },
    background: {
      default: "#212529", // Off-black background
      paper: "#1e1e1e", // A slightly lighter shade for paper/card elements
    },
    action: {
      disabled: "#808080", // Super light grey for disabled elements
      disabledBackground: "#E0E0E0", // Super light grey for disabled elements
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 4,
});

export const lightTheme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      color: "#212529", // Dark text
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      color: "#212529",
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 700,
      color: "#212529",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: "#212529",
    },
    h5: {
      fontSize: "1.2rem",
      fontWeight: 700,
      color: "#212529",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
      color: "#212529",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#212529", // Dark text
    },
    body2: {
      fontSize: "0.9rem",
      fontWeight: 400,
      color: "#212529",
    },
  },
  palette: {
    mode: "light", // Enables light mode
    primary: {
      main: "#212529", // Dark color for primary
    },
    secondary: {
      main: "#FAF9F6", // off white (same as dark theme)
    },
    background: {
      default: "#FFFFFF", // White background
      paper: "#FAF9F6", // A slightly lighter shade for paper/card elements
    },
    action: {
      disabled: "#E0E0E0", // Super light grey for disabled elements
    },
  },

  shape: {
    borderRadius: 8,
  },
  spacing: 4,
});

export default darkTheme;
