import { Stack, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import { MonkeyTextField } from "../../components/MonkeyTextField";

interface ContactInfoCreatorProps {
  control: any;
  errors: any;
  inline?: boolean;
}

export const ContactInfoCreator = ({
  control,
  errors,
  inline = false,
}: ContactInfoCreatorProps) => {
  const nameController = useController({
    name: "primaryContact.contactName",
    control,
  }).field;
  const linkController = useController({
    name: "primaryContact.link",
    control,
  }).field;
  const phoneNumberController = useController({
    name: "primaryContact.phoneNumber",
    control,
  }).field;
  return (
    <Stack width="100%" alignItems="center">
      <Typography component="span" variant="h5">
        Contact Details
      </Typography>
      <Typography component="span" variant="body2">
        Provide at least one
      </Typography>
      <Stack direction={inline ? "row" : "column"} spacing={1} paddingTop="8px">
        <MonkeyTextField onChange={nameController.onChange} label="Name" />
        <MonkeyTextField
          onChange={linkController.onChange}
          label="Website"
          errorMessage={
            !!errors.primaryContact?.link
              ? "Please provide a valid url"
              : undefined
          }
        />
        <MonkeyTextField
          onChange={phoneNumberController.onChange}
          label="Phone number"
        />
      </Stack>
    </Stack>
  );
};
