import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { AutoEventCreateForm } from "./requestTypes";

export const SpecificLocationSelect = ({
  register,
  errors,
}: {
  register: UseFormRegister<AutoEventCreateForm>;
  errors?: FieldErrors<AutoEventCreateForm>;
}): React.ReactElement => {
  return (
    <Stack spacing={2}>
      <Stack>
        <Typography component="span" variant="body1">
          Where will you throw your party?
        </Typography>
        <Typography component="span" variant="body1">
          This is the address guests will see.
        </Typography>
      </Stack>
      <TextField
        {...register("location.specific")}
        label="Address"
        error={!!errors?.location?.message}
        helperText={errors?.location?.message}
      />
    </Stack>
  );
};
