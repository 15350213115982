import { Box, Checkbox, Stack, Typography } from "@mui/material";
import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { MonkeyTooltip } from "../../components/Tooltip";
import theme from "../../theme";
import { AutoEventCreateForm, FeatureTypes } from "./requestTypes";

// const FEATURE_OPTIONS = ["Venue", "Performer", "Snacks", "Catering", "Drinks"];
const FEATURE_OPTIONS: FeatureTypes[] = [
  FeatureTypes.Venue,
  FeatureTypes.Performer,
  FeatureTypes.Snacks,
  FeatureTypes.Drinks,
  FeatureTypes.Sponsorships,
];

const DISABLED_OPTIONS: FeatureTypes[] = [
  FeatureTypes.Snacks,
  FeatureTypes.Drinks,
];

interface FeatureSelectProps {
  selectedFeatures: FeatureTypes[];
  setSelectedFeatures: (features: FeatureTypes[]) => void;
  setValue: UseFormSetValue<AutoEventCreateForm>;
}

export const FeatureSelectPage = ({
  selectedFeatures,
  setSelectedFeatures,
  setValue,
}: FeatureSelectProps): React.ReactElement => {
  return (
    <>
      <Box width="100%" display="flex" justifyContent="center">
        <Typography component="span" variant="body1">
          Welcome to Auto Create!
        </Typography>
      </Box>
      <FeatureSelect
        selectedFeatures={selectedFeatures}
        setSelectedFeatures={setSelectedFeatures}
        setValue={setValue}
      />
    </>
  );
};

const FeatureSelect = ({
  selectedFeatures,
  setSelectedFeatures,
  setValue,
}: FeatureSelectProps): React.ReactElement => {
  return (
    <Stack spacing={1}>
      <Typography component="span" variant="body1">
        <>First off, which features do you want?</>
      </Typography>
      {FEATURE_OPTIONS.map((feature: FeatureTypes) => {
        const isDisabled = DISABLED_OPTIONS.includes(feature);

        const theEl = (
          <Stack direction="row" spacing={1} alignItems="center" key={feature}>
            <Checkbox
              disabled={isDisabled}
              checked={selectedFeatures.includes(feature)}
              onChange={(): void => {
                if (selectedFeatures.includes(feature)) {
                  // in order to get appropriate performer validation, need to set and unset it appropriately here
                  // otherwise onSubmit won't work as the performerOption wont be valid
                  if (feature === FeatureTypes.Performer) {
                    setValue("performersOptions", undefined);
                  }
                  setSelectedFeatures(
                    selectedFeatures.filter(
                      (curr: FeatureTypes) => curr !== feature
                    )
                  );
                } else {
                  // in order to get appropriate performer validation, need to set and unset it appropriately here
                  // otherwise onSubmit won't work as the performerOption wont be valid
                  if (feature === FeatureTypes.Performer) {
                    setValue("performersOptions", {
                      performerType: undefined,
                      specialty: undefined,
                    });
                  }
                  setSelectedFeatures([...selectedFeatures, feature]);
                }
              }}
            />
            <Typography
              component="span"
              variant="body2"
              color={isDisabled ? theme.palette.action.disabled : undefined}
            >
              {feature.toString()}
            </Typography>
          </Stack>
        );

        if (isDisabled) {
          return (
            <MonkeyTooltip
              title="Coming soon..."
              placement="right"
              offsetX={-150}
              key={feature}
            >
              {theEl}
            </MonkeyTooltip>
          );
        } else if (feature === FeatureTypes.Sponsorships) {
          return (
            <MonkeyTooltip
              title="Open to allowing companies to support your event and promote their brand?"
              placement="right"
              offsetX={-130}
              key={feature}
            >
              {theEl}
            </MonkeyTooltip>
          );
        }
        return theEl;
      })}
    </Stack>
  );
};
