import { Box, Button, Typography } from "@mui/material";
import React from "react";
import theme from "../theme";
import { MonkeyTooltip } from "./Tooltip";

interface MonkeyButtonProps {
  onClick?: (event: React.SyntheticEvent) => void;
  type?: "submit";
  text?: string;
  icon?: React.ReactElement;
  width?: string;
  textColor?: string;
  textSize?: "body1" | "body2" | "h1" | "h2" | "h3";
  disabled?: boolean;
  height?: string;
  border?: boolean;
  tooltip?: string;
}

export const MonkeyButton = ({
  onClick,
  width,
  textColor = theme.palette.primary.main,
  text,
  type,
  icon,
  textSize = "body1",
  disabled,
  height,
  border,
  tooltip,
}: MonkeyButtonProps): React.ReactElement => {
  const textComponent = textSize.includes("body") ? "span" : "h2";

  const ActualButtonComponent = (
    <Button
      type={type}
      onClick={onClick}
      disabled={disabled}
      sx={{
        minWidth: 0,
        width: width,
        height: height,
        border: border ? "1px solid rgba(255, 255, 255, 0.3)" : undefined,
        backgroundColor: disabled
          ? theme.palette.action.disabledBackground
          : undefined,
      }}
    >
      {text && (
        <Typography
          component={textComponent}
          minWidth="none"
          variant={textSize}
          color={disabled ? theme.palette.action.disabled : textColor}
          sx={{ textTransform: "none" }}
        >
          {text}
        </Typography>
      )}
      {icon}
    </Button>
  );

  if (tooltip) {
    return (
      <Box width={width} height={height}>
        <MonkeyTooltip title={tooltip} sx={{ width: width, height: height }}>
          <div>{ActualButtonComponent}</div>
        </MonkeyTooltip>
      </Box>
    );
  }

  return (
    <Box width={width} height={height}>
      {ActualButtonComponent}
    </Box>
  );
};
