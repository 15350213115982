import {
  Performer,
  PerformerSpecialty,
  PerformerType,
} from "../apiClient/data-contracts";
import {
  useListPerformerSpecialtiesQuery,
  useListPerformerTypesQuery,
} from "../endpoints";

export const useGetDisplayForPerformer = (
  performer: Performer | undefined
): string | undefined => {
  const { data: performerSpecialties } = useListPerformerSpecialtiesQuery();
  const { data: performerTypes } = useListPerformerTypesQuery();
  if (!performer) {
    return undefined;
  }

  const type = performerTypes?.find((type: PerformerType): boolean => {
    return type.id === performer.type;
  });
  const specialties = performerSpecialties
    ?.filter((specialty: PerformerSpecialty): boolean => {
      return !!(
        performer.specialties &&
        specialty.id &&
        performer.specialties.includes(specialty.id)
      );
    })
    .map((specialty: PerformerSpecialty): string => {
      return specialty.name;
    });

  if (specialties?.length && type?.name) {
    return `${type.name} - ${specialties.join(", ")}`;
  } else if (type?.name) {
    return type.name;
  }
  return undefined;
};
