import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { MonkeyUser } from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import { MonkeyLoading } from "../components/Loading";
import { MonkeyTextField } from "../components/MonkeyTextField";
import {
  useGetUserInfoQuery,
  useUpdateMonkeyPasswordMutation,
  useUpdateMonkeyUserMutation,
} from "../endpoints";

const UpdatableUserName = ({
  userData,
  fetching,
}: {
  userData: MonkeyUser;
  fetching: boolean;
}): React.ReactElement => {
  const [editMode, setEditMode] = React.useState(false);
  const [firstName, setFirstName] = React.useState(userData.first_name);
  const [lastName, setLastName] = React.useState(userData.last_name);

  const [updateUserInfo, { isLoading: isUserUpdating }] =
    useUpdateMonkeyUserMutation();

  const updateName = async (): Promise<void> => {
    if (!userData.id || !firstName || !lastName || isUserUpdating) {
      return;
    }

    // no changes
    if (firstName === userData.first_name && lastName === userData.last_name) {
      return;
    }

    const updatedUserData = {
      ...userData,
      first_name: firstName,
      last_name: lastName,
    };
    await updateUserInfo(updatedUserData).unwrap();
    setEditMode(false);
  };

  if (isUserUpdating || fetching) {
    return <MonkeyLoading />;
  }

  if (editMode) {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <MonkeyTextField
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          label="First name"
        />
        <MonkeyTextField
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          label="Last name"
        />
        <Box onClick={updateName} sx={{ ":hover": { cursor: "pointer" } }}>
          <CheckIcon color="success" sx={{ width: "20px", height: "20px" }} />
        </Box>
        <Box
          onClick={(): void => {
            setFirstName(userData.first_name);
            setLastName(userData.last_name);
            setEditMode(false);
          }}
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          <CancelIcon color="error" sx={{ width: "20px", height: "20px" }} />
        </Box>
      </Stack>
    );
  }
  return (
    <Typography
      component="span"
      variant="body1"
      sx={{ ":hover": { cursor: "pointer" } }}
      onClick={(): void => {
        setEditMode(true);
      }}
    >
      Name: {userData.first_name} {userData.last_name}
    </Typography>
  );
};

export const UpdatablePassword = (): React.ReactElement => {
  const [updatePassword, { isLoading }] = useUpdateMonkeyPasswordMutation();
  const [editMode, setEditMode] = React.useState(false);
  const [oldPwd, setOldPwd] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmedPwd, setConfirmedPwd] = React.useState("");

  const updatePwdWrapper = async (): Promise<void> => {
    if (password !== confirmedPwd || !oldPwd || isLoading) {
      return;
    }
    await updatePassword({
      old_password: oldPwd,
      new_password: password,
    }).unwrap();
    setEditMode(false);
  };

  const passwordsDontMatch = password !== confirmedPwd;
  const missingOldPwd = !oldPwd.length;

  if (!editMode) {
    return (
      <MonkeyButton
        text="Update password"
        onClick={(): void => setEditMode(true)}
      />
    );
  }
  return (
    <Stack spacing={1}>
      <MonkeyTextField
        onChange={(event) => setOldPwd(event.target.value)}
        disabled={isLoading}
        placeholder="Old password"
        type="password"
      />
      <MonkeyTextField
        onChange={(event) => setPassword(event.target.value)}
        disabled={isLoading}
        placeholder="Password"
        type="password"
      />
      <MonkeyTextField
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            updatePwdWrapper();
          }
        }}
        placeholder="Confirm password"
        type="password"
        disabled={isLoading}
        onChange={(event) => setConfirmedPwd(event.target.value)}
        errorMessage={passwordsDontMatch ? "Passwords do not match" : undefined}
      />

      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        justifyContent="center"
      >
        <Box
          onClick={updatePwdWrapper}
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          <CheckIcon color="success" sx={{ width: "20px", height: "20px" }} />
        </Box>
        <Box
          onClick={(): void => {
            setPassword("");
            setConfirmedPwd("");
            setEditMode(false);
          }}
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          <CancelIcon color="error" sx={{ width: "20px", height: "20px" }} />
        </Box>
      </Stack>
    </Stack>
  );
};
export const UserProfile = (): React.ReactElement => {
  const {
    currentData: userData,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useGetUserInfoQuery();

  if (isUserLoading) {
    return <MonkeyLoading />;
  }

  if (!userData) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <UpdatableUserName userData={userData} fetching={isUserFetching} />
      <Typography component="span" variant="body1">
        Phone: {userData.phone_number}
      </Typography>
      <UpdatablePassword />
    </Stack>
  );
};
