import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { MonkeyButton } from "../components/Button";
import { useGetPerformerForCurrUserQuery } from "../endpoints";

export const PerformersSection = (): React.ReactElement => {
  const navigate = useNavigate();

  const { data: performerInfo, isLoading: performerLoading } =
    useGetPerformerForCurrUserQuery();

  if (performerLoading) {
    return (
      <Typography variant="body1" component="span" paddingBottom="16px">
        Loading...
      </Typography>
    );
  }

  return (
    <Stack spacing={1} width="100%" alignItems="center" textAlign="center">
      <Typography variant="body1" component="span" paddingX="8px">
        My performer profile
      </Typography>
      <MonkeyButton
        onClick={(): void => {
          navigate("/performers/create");
        }}
        text={!!performerInfo ? "Edit" : "Create"}
      />
    </Stack>
  );
};
