import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { MonkeyButton } from "../../components/Button";

export const NavToManualCreate = ({
  setCurrIndex,
}: {
  setCurrIndex: (idx: number) => void;
}): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <Stack spacing={1} width="100%" alignItems="center">
      <Typography component="span" variant="body1">
        If you don't require any features, it's easier to use the 'Manual' party
        create
      </Typography>
      <Stack spacing={1} direction="row">
        <MonkeyButton
          text="Previous"
          onClick={(): void => {
            // @ts-ignore
            setCurrIndex((prev) => prev - 1);
          }}
        />
        <MonkeyButton
          text="Manual Create"
          onClick={(): void => navigate("/event/edit")}
        />
      </Stack>
    </Stack>
  );
};
