import { MinMax } from "../logic/types";

export function toTitleCase(str: string | null | undefined) {
  if (!str) {
    return "";
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function caseInsensitiveIncludes(
  str: string | undefined,
  substr: string | undefined
): boolean {
  if (!str) {
    return false;
  } else if (!substr) {
    return true;
  }
  return str.toLowerCase().includes(substr.toLowerCase());
}

export function getDisplayForMinMaxObj({
  minMaxObj,
  prefix = "",
  maxPostString = "",
}: {
  minMaxObj: MinMax | undefined;
  prefix?: string;
  maxPostString?: string;
}): string | undefined {
  if (!minMaxObj) {
    return minMaxObj;
  }

  const rangeMin = minMaxObj.min;
  const rangeMax = minMaxObj.max;

  // Minimum and Maximum are defined
  if (
    rangeMin !== undefined &&
    rangeMin !== null &&
    rangeMax !== undefined &&
    rangeMax !== null
  ) {
    return `${prefix}${rangeMin} - ${prefix}${rangeMax}`;
  }
  // Only maximum is defined
  if (rangeMax !== undefined && rangeMax !== null) {
    return maxPostString
      ? `${prefix}${rangeMax} ${maxPostString}`
      : `${prefix}${rangeMax}`;
  }
  // Only minimum is defined
  if (rangeMin !== undefined && rangeMin !== null) {
    return `${prefix}${rangeMin}+`;
  }
  return undefined;
}
