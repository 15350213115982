import React from "react";
import { z } from "zod";
import { roughLocSchema } from "../../Locations/Map/types";
import { minMaxFormSchema } from "../../logic/types";

export interface PerPageConfig {
  name: string;
  element: React.ReactElement;
  errorFields: string[];
}

export enum FeatureTypes {
  Venue = "Venue",
  Performer = "Performer",
  Snacks = "Snacks",
  Drinks = "Drinks",
  Sponsorships = "Sponsorships",
}

const FeaturesEnumZod = z.nativeEnum(FeatureTypes);

export enum SetupPages {
  //   ManualLocation = "ManualLocation",
  //   AutoLocation = "AutoLocation",
  FeatureSelect = "FeatureSelect",
  Location = "Location",
  ExtraSpecs = "ExtraSpecs",
  PerformerSelect = "PerformerSelect",
  FnBSelect = "FnBSelect",
}

const locationFormSchema = z
  .object({
    rough: z.optional(roughLocSchema),
    specific: z.optional(z.string()),
  })
  .refine(
    (data: any) => {
      if (data.rough === undefined && !data.specific) {
        return false;
      }
      return true;
    },
    {
      message: "You must specify a location",
    }
  );

const performersOptionsSchema = z
  .object({
    performerType: z.string().optional(),
    specialty: z.array(z.string()).optional(),
  })
  .refine(
    (data: any) => {
      if (!data.performerType || !data.specialty?.length) {
        return false;
      }
      return true;
    },
    {
      message: "You must specify a performer",
    }
  );

export type PerformerOption = z.infer<typeof performersOptionsSchema>;

export const autoEventCreateFormSchema = z.object({
  // general details
  selectedFeatures: z.array(FeaturesEnumZod),
  budget: minMaxFormSchema,
  attendees: minMaxFormSchema,
  eventTime: z.date(),
  description: z.string().optional(),
  coverCharge: z.number().optional(),
  // specific features
  location: locationFormSchema,
  performersOptions: performersOptionsSchema.optional(),
});

export type AutoEventCreateForm = z.infer<typeof autoEventCreateFormSchema>;
