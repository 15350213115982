import { File, MonkeyUser } from "../apiClient/data-contracts";

const REGION = "la"; // If German region, set this to an empty string: ''
const BASE_HOSTNAME = "storage.bunnycdn.com";
const HOSTNAME = REGION ? `${REGION}.${BASE_HOSTNAME}` : BASE_HOSTNAME;
const STORAGE_ZONE_NAME = "evenmonkey-file-storage";
const ACCESS_KEY = "f52d6a57-6ea1-4a4f-ad6ed17568f2-7425-4f53";

interface BunnyUploadProps {
  file: any;
  fileName: string;
}
const uploadFileToBunnyCDN = async ({ file, fileName }: BunnyUploadProps) => {
  const url = `https://${HOSTNAME}/${STORAGE_ZONE_NAME}/${fileName}`;

  try {
    const response = await fetch(url, {
      method: "PUT", // Or 'POST', depending on your CDN's requirements
      body: file,
      headers: {
        AccessKey: ACCESS_KEY,
      },
    });

    if (!response.ok) throw new Error("Upload failed");
    const data = await response.json();
    console.log("Upload successful:", data);
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

interface FileUploadProps {
  file: any;
  createEmptyFile: any;
  updateEmptyFile: any;
  userData: MonkeyUser;
  locationId?: string;
  performerId?: string;
  eventId?: string;
}

export const createBackendAndUploadFile = ({
  file,
  createEmptyFile,
  updateEmptyFile,
  locationId,
  performerId,
  eventId,
  userData,
}: FileUploadProps) => {
  // then come back, upload the file with the returned uuid
  const fileToCreate: File = {
    name: `${userData.first_name}'s upload`,
    uploading_user: userData.id,
    event_location: locationId,
    performer: performerId,
    event: eventId,
  };
  let fileToUpdate = undefined;

  createEmptyFile(fileToCreate)
    .unwrap()
    .then((result: File) => {
      if (!result.id) {
        // todo: toast a fail
        return;
      }
      let statusToUpdateTo;
      const extension = file.type.split("/")[1];
      try {
        uploadFileToBunnyCDN({
          file,
          fileName: `${result.id}.${file.type.split("/")[1]}`,
        });
        statusToUpdateTo = "ACTIVE";
      } catch (e) {
        statusToUpdateTo = "FAILED";
      }
      fileToUpdate = {
        id: result.id,
        state: statusToUpdateTo,
        url: `https://eventmonkey.b-cdn.net/${result.id}.${extension}`,
      };
      console.log(fileToUpdate);
      if (!fileToUpdate) {
        return;
      }
      updateEmptyFile(fileToUpdate);
    });
};
