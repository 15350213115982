import EditIcon from "@mui/icons-material/Edit";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import { useNavigate } from "react-router";
import { AutoFlowCreationRequest, Event } from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import {
  useGetEventsForHostQuery,
  useGetUserInfoQuery,
  useListAutoFlowRequestsQuery,
} from "../endpoints";

export const EventsAsHostSection = (): React.ReactElement => {
  const navigate = useNavigate();
  const { data: userInfo, isLoading: isUserLoading } = useGetUserInfoQuery();
  const { data: eventsAsHost, isLoading: isLoadingAsHost } =
    useGetEventsForHostQuery();
  const { data: autoRequests, isLoading: isAutoRequestsLoading } =
    useListAutoFlowRequestsQuery();

  if (isLoadingAsHost || isAutoRequestsLoading || isUserLoading) {
    return (
      <Box display="flex" paddingTop="4px" alignItems="center" padding="8px">
        <Typography component="span" variant="body1">
          Loading...
        </Typography>
      </Box>
    );
  }

  // for non-admins, this wont filter anything down since they can only view their own requests
  // for admins, this filters it down as they can see all requests
  const pertinentRequests = autoRequests?.filter(
    (request: AutoFlowCreationRequest) =>
      request.requesting_user_id === userInfo?.id &&
      !request.response_params &&
      !!request.id
  );

  const HostSection = eventsAsHost?.length ? (
    <Stack spacing={1}>
      <Typography variant="body1" component="span">
        Your events (host):
      </Typography>
      {eventsAsHost?.map((value: Event) => {
        return (
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            key={value.id}
          >
            <MonkeyButton
              width="100%"
              text={value.name}
              onClick={(): void => {
                if (!value?.id) {
                  return;
                }
                navigate(`event/view/${value.id}`);
              }}
            />
            <MonkeyButton
              width="30px"
              onClick={(): void => {
                if (!value?.id) {
                  return;
                }
                navigate(`event/edit/${value.id}`);
              }}
              icon={
                <EditIcon
                  sx={{
                    color: "white",
                    width: "16px",
                    height: "16px",
                  }}
                />
              }
            />
          </Stack>
        );
      })}
    </Stack>
  ) : (
    <Typography variant="body1" component="span">
      You aren't hosting any events yet.
    </Typography>
  );

  const AutoRequestSection = pertinentRequests?.length ? (
    <Stack spacing={1}>
      <Typography variant="body1" component="span">
        Your requests:
      </Typography>
      {pertinentRequests?.map((autoRequest: AutoFlowCreationRequest) => {
        const formattedDate = !!autoRequest.request_params.party_date
          ? format(parseISO(autoRequest.request_params.party_date), "MMMM dd")
          : null;
        const displayStr = formattedDate
          ? `Request for ${formattedDate}`
          : `${autoRequest.requesting_user_str}'s request`;
        return (
          <MonkeyButton
            width="100%"
            key={autoRequest.id}
            text={displayStr}
            onClick={(): void => {
              if (!autoRequest?.id) {
                return;
              }
              navigate(`autoflow/view/${autoRequest.id}`);
            }}
          />
        );
      })}
      <Divider
        orientation="horizontal"
        sx={{ color: "white", width: "100%" }}
      />
    </Stack>
  ) : (
    <></>
  );

  return (
    <Stack spacing={3} paddingTop="4px" alignItems="center">
      {HostSection}
      <Divider orientation="horizontal" sx={{ color: "white", width: "50%" }} />
      {AutoRequestSection}
      <MonkeyButton
        text="Create an event"
        onClick={(): void => {
          navigate("event/create");
        }}
      />
    </Stack>
  );
};
