import { Box, Typography } from "@mui/material";
import React from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

export const MonkeyDrop = ({
  onDrop,
}: {
  onDrop: (files: any[]) => void;
}): React.ReactElement => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      type: "BOX",
      accept: [NativeTypes.FILE],
      drop(actualFiles: { files: any[] }) {
        onDrop(actualFiles.files);
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
          //   isDragging: monitor.isDragging(),
        };
      },
    }),
    []
  );
  const isActive = isOver && canDrop;
  return (
    <Box
      ref={drop}
      width="200px"
      height="80px"
      borderRadius="4px"
      border="1px solid rgba(255, 255, 255, 0.3)"
      padding="8px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography component="span" variant="body1">
        {isActive ? "Release" : "Drop your photos here"}
      </Typography>
    </Box>
  );
};
