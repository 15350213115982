/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Notification } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Notifications<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags notifications
   * @name NotificationsList
   * @request GET:/notifications/
   * @secure
   */
  notificationsList = (params: RequestParams = {}) =>
    this.request<Notification[], any>({
      path: `/notifications/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name NotificationsCreate
   * @request POST:/notifications/
   * @secure
   */
  notificationsCreate = (data: Notification, params: RequestParams = {}) =>
    this.request<Notification, any>({
      path: `/notifications/`,
      method: "POST",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name NotificationsRead
   * @request GET:/notifications/{id}/
   * @secure
   */
  notificationsRead = (id: string, params: RequestParams = {}) =>
    this.request<Notification, any>({
      path: `/notifications/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name NotificationsUpdate
   * @request PUT:/notifications/{id}/
   * @secure
   */
  notificationsUpdate = (id: string, data: Notification, params: RequestParams = {}) =>
    this.request<Notification, any>({
      path: `/notifications/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags notifications
   * @name NotificationsPartialUpdate
   * @request PATCH:/notifications/{id}/
   * @secure
   */
  notificationsPartialUpdate = (id: string, data: Notification, params: RequestParams = {}) =>
    this.request<Notification, any>({
      path: `/notifications/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
}
