import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { GuestAtEvent } from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import { useListEventsAsGuestQuery } from "../endpoints";

export const EventsAsGuestSection = (): React.ReactElement => {
  const navigate = useNavigate();
  const { data: eventsAsGuest, isLoading: isLoadingAsGuest } =
    useListEventsAsGuestQuery();
  if (isLoadingAsGuest) {
    return (
      <Box display="flex" paddingTop="4px" alignItems="center" padding="8px">
        <Typography component="span" variant="body1">
          Loading...
        </Typography>
      </Box>
    );
  }
  if (!eventsAsGuest?.length) {
    return (
      <Stack paddingTop="4px" alignItems="center" textAlign="center">
        <Typography variant="body1" component="span">
          You aren't attending any events yet.
        </Typography>
        <MonkeyButton
          text="Browse events"
          onClick={(): void => {
            navigate("browse-events");
          }}
        />
      </Stack>
    );
  }
  return (
    <Stack spacing={1} paddingTop="4px" alignItems="center">
      <Typography variant="body1" component="span">
        Your events (guest):
      </Typography>
      {eventsAsGuest?.map((value: GuestAtEvent) => {
        return (
          <Stack
            direction="row"
            spacing={2}
            key={value.guest_info.id}
            alignItems="center"
            paddingBottom="8px"
          >
            <Link
              to={`event/view/${value.event_info.id}`}
              style={{ color: "#2196f3", textDecoration: "none" }}
            >
              {value.event_info.name}
            </Link>
            {value.guest_info.status && (
              <Typography component="span" variant="body1">
                Attending: {value.guest_info.status}
              </Typography>
            )}
          </Stack>
        );
      })}
      <Divider orientation="horizontal" sx={{ color: "white", width: "50%" }} />
      <MonkeyButton
        text="Browse events"
        onClick={(): void => {
          navigate("browse-events");
        }}
      />
    </Stack>
  );
};
