import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { MonkeyButton } from "../components/Button";

export const ConnectionsSection = (): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <Stack
      spacing={1}
      paddingTop="4px"
      alignItems="center"
      padding="8px"
      textAlign="center"
    >
      <Typography variant="body1" component="span">
        Browse Performers
      </Typography>
      <MonkeyButton
        text="Browse"
        onClick={(): void => {
          navigate("browse-performers");
        }}
      />
      <Typography variant="body1" component="span">
        Browse Locations
      </Typography>
      <MonkeyButton
        text="Browse"
        onClick={(): void => {
          navigate("browse-locations");
        }}
      />
    </Stack>
  );
};
