import { Checkbox, Divider, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { PerformersSection } from "../HomePage/PerformersSection";
import { Performer } from "../apiClient/data-contracts";
import { MonkeyTooltip } from "../components/Tooltip";
import {
  useGetUserInfoQuery,
  useListPerformerSpecialtiesQuery,
  useListPerformerTypesQuery,
  useListPerformersQuery,
  useUpdateMonkeyUserMutation,
} from "../endpoints";
import { useGetDisplayForPerformer } from "../helpers/hooks";
import { caseInsensitiveIncludes } from "../helpers/utils";
import theme from "../theme";

const PerformerCard = ({
  performer,
  isFavorite,
  setAsFavorite,
  loadingFavorites,
}: {
  performer: Performer;
  isFavorite?: boolean;
  setAsFavorite?: (performerId: string) => Promise<void>;
  loadingFavorites?: boolean;
}): React.ReactElement => {
  const navigate = useNavigate();

  const displayString = useGetDisplayForPerformer(performer);

  const actualCard = (
    <Stack
      width="100%"
      sx={{ ":hover": { cursor: "pointer" } }}
      onClick={(): void => navigate(`/performers/view/${performer.id}`)}
    >
      <Typography component="h3" variant="h3" fontWeight="500">
        {performer.name}
      </Typography>
      {displayString && (
        <Typography component="span" variant="body1">
          {displayString}
        </Typography>
      )}
    </Stack>
  );

  if (isFavorite === undefined || !setAsFavorite) {
    return (
      <Stack
        border={`1px solid ${theme.palette.secondary.main}`}
        padding="8px"
        borderRadius="4px"
      >
        {actualCard}
      </Stack>
    );
  }

  return (
    <Stack
      border={`1px solid ${theme.palette.secondary.main}`}
      padding="8px"
      borderRadius="4px"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        divider={
          <Divider
            orientation="vertical"
            sx={{ color: "lightgrey", height: "100%" }}
          />
        }
      >
        {actualCard}
        <MonkeyTooltip placement="right" title="Favorite?">
          <Checkbox
            disabled={loadingFavorites}
            checked={isFavorite}
            onClick={(): Promise<void> => setAsFavorite(performer.id!)}
            sx={{ marginLeft: "8px" }}
          />
        </MonkeyTooltip>
      </Stack>
    </Stack>
  );
};

const BrowseFilters = ({
  generalFilter,
  setGeneralFilter,
}: {
  generalFilter: string | undefined;
  setGeneralFilter: (value: string | undefined) => void;
}): React.ReactElement => {
  return (
    <Stack spacing={1} width="100%">
      <TextField
        value={generalFilter}
        placeholder="Filter"
        onChange={(event: any): void => {
          setGeneralFilter(event.target.value);
        }}
      />
    </Stack>
  );
};

export const GeneralBrowse = (): React.ReactElement => {
  // state
  const [generalFilter, setGeneralFilter] = React.useState<string | undefined>(
    undefined
  );

  // RTK
  const { data: performerOptions, isLoading: isPerformersLoading } =
    useListPerformersQuery();
  const { isLoading: isSpecialtiesLoading } =
    useListPerformerSpecialtiesQuery();
  const { isLoading: isTypesLoading } = useListPerformerTypesQuery();
  const { data: userData, isFetching: isFetchingUser } = useGetUserInfoQuery();
  const [updateUser, { isLoading: isUpdatingUser }] =
    useUpdateMonkeyUserMutation();

  // callbacks
  const filteredOptions = React.useMemo(() => {
    if (!performerOptions) {
      return [];
    }
    let remaining = [
      ...performerOptions.filter((performer: Performer) => !!performer.id),
    ];
    if (generalFilter) {
      remaining = remaining.filter(
        (value) =>
          caseInsensitiveIncludes(value.name ?? "", generalFilter) ||
          caseInsensitiveIncludes(
            // (value.specialties || "").toString(),
            "",
            generalFilter
          ) ||
          //   caseInsensitiveIncludes((value.type || "").toString(), generalFilter)
          caseInsensitiveIncludes("".toString(), generalFilter)
      );
    }
    return remaining;
  }, [generalFilter, performerOptions]);

  const setAsFavorite = async (performerId: string): Promise<void> => {
    if (!userData) {
      return;
    }
    const oldFavPerformers = userData.favorite_performers ?? [];
    let newFavPerformers;
    if (oldFavPerformers.includes(performerId)) {
      newFavPerformers = [
        ...oldFavPerformers.filter((perfId: string) => perfId !== performerId),
      ];
    } else {
      newFavPerformers = [...oldFavPerformers, performerId];
    }

    const newUserData = { ...userData, favorite_performers: newFavPerformers };
    await updateUser(newUserData);
  };

  if (isPerformersLoading || isSpecialtiesLoading || isTypesLoading) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }

  return (
    <Stack spacing={2} alignItems="center">
      <Typography component="h2" variant="h3">
        Performers
      </Typography>
      <PerformersSection />
      <BrowseFilters
        generalFilter={generalFilter}
        setGeneralFilter={setGeneralFilter}
      />
      <Stack
        height="70vh"
        overflow="auto"
        spacing={1}
        justifyContent="flex-start"
        width="350px"
      >
        {filteredOptions?.map((performer) => {
          return (
            <PerformerCard
              key={performer.id}
              performer={performer}
              setAsFavorite={setAsFavorite}
              loadingFavorites={isFetchingUser}
              isFavorite={
                !!(
                  performer.id &&
                  userData?.favorite_performers?.includes(performer.id)
                )
              }
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
