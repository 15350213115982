import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MonkeyButton } from "../components/Button";

export const CreateHomeScreen = (): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <Stack spacing={1}>
      <Typography component="span" variant="body1">
        Choose how you wish to create your event
      </Typography>
      <Stack direction="row" spacing={1} width="100%" justifyContent="center">
        <MonkeyButton
          text="Manual"
          onClick={(): void => navigate("/event/edit")}
        />
        <MonkeyButton
          text="Auto"
          onClick={(): void => navigate("/event/create-auto")}
        />
      </Stack>
    </Stack>
  );
};
