import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { ContactInfoCreator } from "../../../ContactInfo/components/Creator";
import { ContactInfo, EventLocation } from "../../../apiClient/data-contracts";
import { MonkeyButton } from "../../../components/Button";
import { MonkeyDrop } from "../../../components/MonkeyDrop";
import { MonkeyTextField } from "../../../components/MonkeyTextField";
import { NumberInput } from "../../../components/NumberInput";
import {
  useCreateContactInfoMutation,
  useCreateEmptyFileMutation,
  useCreateLocationMutation,
  useGetUserInfoQuery,
  useUpdateFileMutation,
} from "../../../endpoints";
import { createBackendAndUploadFile } from "../../../external/BunnyCDN";
import { MinMax } from "../../../logic/types";
import { NewLocationSchema, newLocationSchema } from "../types";

export const LocationUploader = (): React.ReactElement => {
  const [files, setFiles] = React.useState<any[]>([]);
  const [createEmptyFile] = useCreateEmptyFileMutation();
  const [updateEmptyFile] = useUpdateFileMutation();
  const [createContactInfo, { isLoading: isCreatingContactInfo }] =
    useCreateContactInfoMutation();
  const [
    createLocation,
    { isLoading: isCreatingLocation, isSuccess: isSuccessLocation },
  ] = useCreateLocationMutation();
  const { data: userData } = useGetUserInfoQuery();

  const { control, handleSubmit, getValues, setValue, formState } =
    useForm<NewLocationSchema>({
      resolver: zodResolver(newLocationSchema),
      defaultValues: {
        primaryContact: undefined,
        name: "", // this will get converted to clean_address
        address: "",
        capacity: {},
        priceRange: {},
        website: "",
      },
    });

  ///////////////////////
  // form registration //
  ///////////////////////
  const nameController = useController({
    name: "name",
    control,
  }).field;
  const addressController = useController({
    name: "address",
    control,
  }).field;
  const websiteController = useController({
    name: "website",
    control,
  }).field;

  const capacityVal = getValues("capacity");
  const setCapacity = React.useCallback(
    (newVal: MinMax): void => {
      setValue("capacity", newVal, { shouldValidate: true });
    },
    [setValue]
  );
  const capacityError = formState.errors.capacity;

  const priceRangeVal = getValues("priceRange");
  const setPriceRange = React.useCallback(
    (newVal: MinMax): void => {
      setValue("priceRange", newVal, { shouldValidate: true });
    },
    [setValue]
  );
  const priceError = formState.errors.priceRange;

  const onSubmit = async (values: NewLocationSchema): Promise<void> => {
    if (!userData) {
      return;
    }

    createContactInfo({
      phone_number: values.primaryContact.phoneNumber,
      contact_name: values.primaryContact.contactName,
      link: values.primaryContact.link,
    })
      .unwrap()
      .then((contactResult: ContactInfo) => {
        createLocation({
          clean_address: values.name,
          address: values.address,
          primary_contact: contactResult.id,
          capacity: values.capacity,
          price_range: values.priceRange,
          website: values.website,
        })
          .unwrap()
          .then((locResult: EventLocation) => {
            files.forEach((file: File) => {
              createBackendAndUploadFile({
                file: file,
                userData,
                locationId: locResult.id,
                createEmptyFile,
                updateEmptyFile,
              });
            });
          });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        alignItems="center"
        spacing={3}
        maxHeight="85vh"
        overflow="auto"
        maxWidth="70vw"
      >
        {/* LOCATION DETAILS */}
        <Typography component="span" variant="h5">
          Location Details
        </Typography>
        <MonkeyTextField onChange={nameController.onChange} label="Name" />
        <MonkeyTextField
          onChange={addressController.onChange}
          label="Address"
        />
        <MonkeyTextField
          onChange={websiteController.onChange}
          label="Website"
        />
        <Typography component="span" variant="body1">
          Capacity
        </Typography>
        <Stack direction="row" spacing={1}>
          <NumberInput
            initialValue={capacityVal.min ?? null}
            label="Minimum"
            onChange={(newVal: any): void => {
              setCapacity({ ...capacityVal, min: newVal });
            }}
            error={!!capacityError}
          />
          <NumberInput
            initialValue={capacityVal.max ?? null}
            label="Maximum"
            onChange={(newVal: any): void => {
              setCapacity({ ...capacityVal, max: newVal });
            }}
            error={!!capacityError}
          />
        </Stack>
        <Typography component="span" variant="body1">
          Price
        </Typography>
        <Stack direction="row" spacing={1}>
          <NumberInput
            initialValue={priceRangeVal.min ?? null}
            label="Minimum"
            onChange={(newVal: any): void => {
              setPriceRange({ ...priceRangeVal, min: newVal });
            }}
            error={!!priceError}
          />
          <NumberInput
            initialValue={priceRangeVal.max ?? null}
            label="Maximum"
            onChange={(newVal: any): void => {
              setPriceRange({ ...priceRangeVal, max: newVal });
            }}
            error={!!priceError}
          />
        </Stack>
        {/* CONTACT INFO */}
        <ContactInfoCreator
          control={control}
          inline
          errors={formState.errors}
        />
        {/* PHOTO UPLOAD */}
        <MonkeyDrop
          onDrop={(newFiles: any[]): void => {
            setFiles((prev) => [...prev, ...newFiles]);
          }}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          {files.map((file: any, idx: number) => {
            return (
              <Box
                maxHeight="200px"
                key={idx}
                sx={{ ":hover": { cursor: "pointer" } }}
                onClick={(): void => {
                  console.log("peepee");
                  const copiedFiles = [...files];
                  copiedFiles.splice(idx, 1);
                  setFiles(copiedFiles);
                }}
              >
                <img
                  style={{ width: "100%", maxHeight: "200px", height: "100%" }}
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                />
              </Box>
            );
          })}
        </Stack>
        <MonkeyButton
          text="Submit"
          type="submit"
          // while creating and after a success, cant hit submit again
          disabled={
            isCreatingContactInfo || isCreatingLocation || isSuccessLocation
          }
        />
      </Stack>
    </form>
  );
};
