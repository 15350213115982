import { Stack, Typography } from "@mui/material";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import React from "react";
import { useNavigate } from "react-router";
import { MonkeyButton } from "../components/Button";
import { MonkeyTextField } from "../components/MonkeyTextField";
import {
  useSendForgotPwdOtpMutation,
  useUpdatePasswordWithTokenMutation,
  useVerifyOtpForgotPwdMutation,
} from "../endpoints";

export const ForgotPassword = (): React.ReactElement => {
  // user identification
  const [phoneNumber, setPhoneNumber] = React.useState("");
  console.log(phoneNumber);
  // verification details
  const [otp, setOtp] = React.useState("");
  const [pwdToken, setPwdToken] = React.useState("");
  const isOtpVerified = !!pwdToken;

  // new password info
  const [newPwd, setNewPwd] = React.useState("");
  const [confirmPwd, setConfirmPwd] = React.useState("");

  // whether to show "success! sign in here"
  const [successfullyUpdatedPwd, setSuccessfullyUpdatedPwd] =
    React.useState(false);

  const navigate = useNavigate();

  // first call to send user an otp
  const [sendForgotPwdOtp, { isLoading: isSendingOtp, isSuccess: sentOtp }] =
    useSendForgotPwdOtpMutation();

  // second call to verify the otp
  const [verifyOtp, { isLoading: isVerifyingOtp }] =
    useVerifyOtpForgotPwdMutation();

  // third call to update the password
  const [updatePwdWithToken, { isLoading: isUpdatingPwd }] =
    useUpdatePasswordWithTokenMutation();

  // button which allows users to perform actions
  const BottomButton = React.useMemo(() => {
    if (!sentOtp) {
      return (
        <MonkeyButton
          onClick={async (): Promise<void> => {
            if (!phoneNumber) {
              return;
            }
            await sendForgotPwdOtp(phoneNumber).unwrap();
          }}
          text="Send OTP"
          disabled={isSendingOtp || sentOtp || !phoneNumber}
        />
      );
    }
    if (!isOtpVerified) {
      return (
        <MonkeyButton
          onClick={async (): Promise<void> => {
            if (!otp || !phoneNumber) {
              return;
            }
            verifyOtp({ phone_number: phoneNumber, otp: otp })
              .unwrap()
              .then((result) => {
                setPwdToken(result.pwd_token);
              });
          }}
          text="Verify OTP"
          disabled={!sentOtp || isVerifyingOtp}
        />
      );
    }
    return (
      <MonkeyButton
        onClick={async (): Promise<void> => {
          if (!phoneNumber || !newPwd || !pwdToken) {
            return;
          }
          if (newPwd !== confirmPwd) {
            return;
          }
          updatePwdWithToken({
            phone_number: phoneNumber,
            pwd_token: pwdToken,
            new_password: newPwd,
          })
            .unwrap()
            .then((result) => {
              setSuccessfullyUpdatedPwd(result);
            });
        }}
        text="Submit password"
        disabled={!newPwd || newPwd !== confirmPwd || isUpdatingPwd}
      />
    );
  }, [
    sentOtp,
    isOtpVerified,
    newPwd,
    confirmPwd,
    isUpdatingPwd,
    isSendingOtp,
    phoneNumber,
    sendForgotPwdOtp,
    isVerifyingOtp,
    otp,
    verifyOtp,
    pwdToken,
    updatePwdWithToken,
  ]);

  // screen to show upon successful reset
  if (successfullyUpdatedPwd) {
    return (
      <Stack spacing={1}>
        <Typography component="span" variant="body1">
          Success!
        </Typography>
        <MonkeyButton
          text="Sign in"
          onClick={(): void => navigate("/sign-in")}
        />
      </Stack>
    );
  }

  return (
    <Stack spacing={1} alignItems="center" width="60%">
      {!sentOtp && (
        // <MonkeyTextField
        //   fullWidth
        //   label="Phone number"
        //   disabled={sentOtp}
        //   onChange={(e: any): void => setPhoneNumber(e.target.value)}
        // />
        <MuiTelInput
          value={phoneNumber}
          defaultCountry="US"
          onChange={(value: string, info: MuiTelInputInfo): void => {
            setPhoneNumber(info.numberValue || "");
          }}
          sx={{ width: "100%" }}
        />
      )}
      {sentOtp && !isOtpVerified && (
        <MonkeyTextField
          fullWidth
          label="OTP"
          disabled={!sentOtp}
          onChange={(e: any): void => setOtp(e.target.value)}
        />
      )}
      {sentOtp && isOtpVerified && (
        <Stack spacing={1} width="100%">
          <MonkeyTextField
            fullWidth
            type="password"
            label="New password"
            onChange={(e: any): void => setNewPwd(e.target.value)}
          />
          <MonkeyTextField
            fullWidth
            type="password"
            label="Confirm"
            onChange={(e: any): void => setConfirmPwd(e.target.value)}
          />
        </Stack>
      )}
      {BottomButton}
    </Stack>
  );
};
