import { z } from "zod";
import { contactInfoSchema } from "../../ContactInfo/types";
import { minMaxFormSchema } from "../../logic/types";

export const newLocationSchema = z.object({
  primaryContact: contactInfoSchema,
  name: z.string(), // this will get converted to clean_address
  address: z.string(),
  capacity: minMaxFormSchema,
  priceRange: minMaxFormSchema,
  website: z.string().url().optional(),
});

export type NewLocationSchema = z.infer<typeof newLocationSchema>;
