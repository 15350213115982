import { Stack, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useParams } from "react-router-dom";
import { Performer, SocialLink } from "../apiClient/data-contracts";
import { MonkeyLink } from "../components/Link";
import {
  useGetPerformerQuery,
  useGetSocialsForPerformerQuery,
  useListPerformerSpecialtiesQuery,
  useListPerformerTypesQuery,
} from "../endpoints";
import { useGetDisplayForPerformer } from "../helpers/hooks";
import { toTitleCase } from "../helpers/utils";

const getPriceDisplayForPerformer = (
  performer: Performer | undefined
): string | undefined => {
  // price is not defined
  if (!performer?.price_range) {
    return undefined;
  }
  // @ts-ignore
  const priceMin = performer.price_range.min;
  // @ts-ignore
  const priceMax = performer.price_range.max;

  // Both prices are defined
  if (
    priceMin !== undefined &&
    priceMin !== null &&
    priceMax !== undefined &&
    priceMax !== null
  ) {
    return `$${priceMin} - $${priceMax}`;
  }

  // Only priceMax is defined
  if (priceMax !== undefined && priceMax !== null) {
    return `$${priceMax}`;
  }
  // Only priceMin is defined
  if (priceMin !== undefined && priceMin !== null) {
    return `$${priceMin}`;
  }
  return undefined;
};

const ContactSection = ({ performer }: { performer?: Performer }) => {
  if (!performer?.primary_contact) {
    return <></>;
  }
  const phoneSection = (
    <Typography component="span" variant="body1">
      Phone: {performer.primary_contact.phone_number}
    </Typography>
  );
  const linkSection = (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography component="span" variant="body1">
        {`Contact ${performer.name} `}
      </Typography>
      {performer.primary_contact.link && (
        <MonkeyLink to={performer.primary_contact.link} text="here" />
      )}
    </Stack>
  );
  if (
    performer.primary_contact.link &&
    performer.primary_contact.phone_number
  ) {
    return (
      <Stack>
        {linkSection}
        {phoneSection}
      </Stack>
    );
  } else if (performer.primary_contact.link) {
    return linkSection;
  } else if (performer.primary_contact.phone_number) {
    return phoneSection;
  }
  return <></>;
};

const SocialsSection = ({ performerId }: { performerId?: string }) => {
  const { data: socialLinks, isLoading: isLinksLoading } =
    useGetSocialsForPerformerQuery(performerId ?? skipToken);

  if (isLinksLoading) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }

  if (!socialLinks) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <Stack spacing={1}>
      {socialLinks.social_links.map((link: SocialLink) => {
        return (
          <MonkeyLink
            to={link.url}
            text={toTitleCase(link.type)}
            key={link.id}
          />
        );
      })}
    </Stack>
  );
};

export const PerformerView = (): React.ReactElement => {
  const { performerId } = useParams();
  const { data: performerData, isFetching } = useGetPerformerQuery(
    performerId ?? skipToken
  );
  const { isLoading: isLinksLoading } = useGetSocialsForPerformerQuery(
    performerId ?? skipToken
  );
  const { isLoading: specialtiesLoading } = useListPerformerSpecialtiesQuery();
  const { isLoading: typesLoading } = useListPerformerTypesQuery();

  const displayString = useGetDisplayForPerformer(performerData);
  const priceDisplay = getPriceDisplayForPerformer(performerData);

  if (!performerId || isFetching || typesLoading || specialtiesLoading) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }
  if (!performerData) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <Stack spacing={1}>
      <Typography component="span" variant="h3" fontWeight="500">
        {performerData.name}
      </Typography>
      <SocialsSection performerId={performerId} />
      {displayString && (
        <Typography component="span" variant="body1">
          {displayString}
        </Typography>
      )}
      {priceDisplay && (
        <Typography component="span" variant="body1">
          {priceDisplay}
        </Typography>
      )}

      <ContactSection performer={performerData} />
    </Stack>
  );
};
