import { Stack, Typography } from "@mui/material";

import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { StringParam, useQueryParam } from "use-query-params";
import { MonkeyButton } from "../components/Button";
import { MonkeyLoading } from "../components/Loading";
import { MonkeyTextField } from "../components/MonkeyTextField";
import {
  eventMonkeyApi,
  useGetGuestViaLinkQuery,
  useGetUserInfoQuery,
  useSendForgotPwdOtpMutation,
  useValidateOTPMutation,
} from "../endpoints";

export const OTPPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // first deduce what context this is
  const [attendPartyLink] = useQueryParam("inviteLink", StringParam);

  // local state
  const [otpVal, setOtpVal] = React.useState("");
  const [otpError, setOtpError] = React.useState<string | undefined>(undefined);
  const [regenerateError, setRegenerateError] = React.useState<
    string | undefined
  >(undefined);

  // RTK
  const { data: guestData, isLoading: isGuestLoading } =
    useGetGuestViaLinkQuery(attendPartyLink ?? skipToken);
  const { data: userData, isLoading: isUserDataLoading } =
    useGetUserInfoQuery();
  const [validateOTP] = useValidateOTPMutation();
  const [regenerateOtp] = useSendForgotPwdOtpMutation();

  // callbacks
  const onSignUpSuccess = React.useCallback(() => {
    if (guestData && attendPartyLink) {
      dispatch(eventMonkeyApi.util.resetApiState());
      navigate(`/event/invite/${attendPartyLink}`);
    } else {
      navigate("/");
    }
  }, [attendPartyLink, dispatch, guestData, navigate]);

  const validateOTPWrapper = async (): Promise<void> => {
    if (!otpVal) {
      return;
    }
    validateOTP({ otp: otpVal })
      .unwrap()
      .then(() => {
        onSignUpSuccess();
      })
      .catch((error) => setOtpError(error.message ?? "Something went wrong"));
  };

  const regenerateOTPWrapper = async (): Promise<void> => {
    if (!userData?.phone_number) {
      return;
    }
    await regenerateOtp(userData.phone_number)
      .unwrap()
      .catch((error) =>
        setRegenerateError(error.message ?? "Something went wrong")
      );
    setOtpError(undefined);
    setOtpVal("");
  };

  if (isUserDataLoading || isGuestLoading) {
    return <MonkeyLoading />;
  }

  return (
    <Stack
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        component="span"
        variant="body1"
        sx={{ paddingBottom: "30px" }}
      >
        Please confirm your phone number first
      </Typography>
      <MonkeyTextField
        fullWidth
        label="OTP"
        value={otpVal}
        onChange={(e: any): void => {
          setOtpError(undefined);
          setOtpVal(e.target.value);
        }}
        errorMessage={otpError}
      />
      <MonkeyButton onClick={validateOTPWrapper} text="Verify" />
      <MonkeyButton onClick={regenerateOTPWrapper} text="Regenerate" />
      {!!regenerateError && (
        <Typography component="span" variant="body1" color="error">
          {regenerateError}
        </Typography>
      )}
    </Stack>
  );
};
