import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../theme";

interface MonkeyButtonProps {
  textSize?: "body1" | "body2" | "h1" | "h2" | "h3";
  disabled?: boolean;
  to: string;
  text: string;
  width?: string;
}

export const MonkeyLink = ({
  to,
  text,
  textSize = "body1",
  disabled,
  width,
}: MonkeyButtonProps): React.ReactElement => {
  const textComponent = textSize.includes("body") ? "span" : "h2";

  return (
    <Box width={width}>
      <Link
        to={to}
        style={{
          color: theme.palette.primary.main, // Set your desired default color
          textDecoration: "none", // Remove underline for better styling
        }}
        target="_blank"
        rel="noreferrer"
      >
        <Typography
          component={textComponent}
          minWidth="none"
          variant={textSize}
          color={
            disabled
              ? theme.palette.action.disabled
              : theme.palette.primary.main
          }
          sx={{ textTransform: "none" }}
        >
          {text}
        </Typography>
      </Link>
    </Box>
  );
};
