import React from "react";

import { GoogleMap } from "@react-google-maps/api";
import { MonkeyLoading } from "../../../components/Loading";
import { LocType } from "../types";

export interface WidthAndHeight {
  width: string;
  height: string;
}

export const MapComponent = ({
  isLoaded,
  mapCenter,
  onLoad,
  onUnmount,
  containerStyle,
}: {
  containerStyle: WidthAndHeight;
  isLoaded: boolean;
  mapCenter: LocType | undefined;
  onLoad: (map: any) => void;
  onUnmount: (map: any) => void;
}): React.ReactElement => {
  if (!isLoaded || !mapCenter) {
    return <MonkeyLoading />;
  }

  return (
    <GoogleMap
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
      }}
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={1}
      onLoad={onLoad}
      onUnmount={onUnmount}
    />
  );
};
