import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import React from "react";
import { Event, Guest, MonkeyUser } from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import { MonkeyTextField } from "../components/MonkeyTextField";
import {
  useCreateSignUpLinksMutation,
  useGetUserInfoQuery,
  useListFriendsQuery,
} from "../endpoints";
import theme from "../theme";
interface InviteNewGuestProps {
  eventData: Event;
  currGuests: Guest[];
  onClose: () => void;
}

// super basic and rough estimation of whether a phone number is complete
const isPhoneValid = (phoneStr: string | null | undefined): boolean => {
  if (!phoneStr) {
    return false;
  }
  if (!phoneStr.startsWith("+")) {
    return false;
  }
  if (phoneStr.length < 8) {
    return false;
  }
  return true;
};

const buildDefaultInviteMessage = (eventData: Event): string => {
  const hostNames = eventData.host_names ?? [undefined];
  let formattedEventHostNames;
  if (hostNames.length > 1) {
    formattedEventHostNames = `${hostNames.slice(0, -1)} and ${
      hostNames[hostNames.length - 1]
    }'s`;
  } else if (hostNames.length) {
    formattedEventHostNames = `${hostNames[0]}'s`;
  }

  let defaultMessage =
    "You've been invited to a new party! You can view it here {link_will_appear_here}";
  if (formattedEventHostNames) {
    defaultMessage = `You've been invited to ${formattedEventHostNames} party! You can view it here {link_will_appear_here}`;
  }
  return defaultMessage;
};

export const InviteNewGuest = ({
  eventData,
  currGuests,
  onClose,
}: InviteNewGuestProps): React.ReactElement => {
  // state
  const [numbersToInvite, setNumbersToInvite] = React.useState<string[]>([]);
  const [currNum, setCurrNum] = React.useState<string>("+1");
  const [friendsToInvite, setFriendsToInvite] = React.useState<MonkeyUser[]>(
    []
  );

  const textsToInvite: (string | MonkeyUser)[] = [
    ...numbersToInvite,
    ...friendsToInvite,
  ];

  const defaultMessage = buildDefaultInviteMessage(eventData);
  const [message, setMessage] = React.useState<string>(defaultMessage);

  // RTK
  const { data: userData, isLoading: isUserDataLoading } =
    useGetUserInfoQuery();
  const { data: friendsInfo, isLoading } = useListFriendsQuery();
  const [createSignUpLinks] = useCreateSignUpLinksMutation();

  // Derived
  const guestUserIds = currGuests?.map((guest: Guest) => guest.user_id);
  const filteredFriends = friendsInfo?.filter(
    // we want people who are friends of the user, but not already a guest
    // TODO: Fix this
    (friend) => friend.id && !guestUserIds?.includes(friend.id)
  );

  // Callbacks
  const createSignUpLinksWrapper = async (): Promise<void> => {
    if (
      (!friendsToInvite.length && !numbersToInvite.length) ||
      !eventData.id ||
      !userData?.id
    ) {
      return;
    }
    await createSignUpLinks({
      event_id: eventData.id,
      phone_numbers: numbersToInvite,
      invited_users: friendsToInvite
        .filter((user) => !!user.id)
        .map((user: MonkeyUser) => user.id!),
      message: message,
    })
      .unwrap()
      .then((_) => onClose());
  };

  const phoneNumSubmit = (): void => {
    if (!isPhoneValid(currNum)) {
      return;
    }
    setNumbersToInvite((prev) => [...prev, currNum]);
    setCurrNum("+1");
  };

  // Final returns
  if (isLoading || !eventData.id) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }

  if (!filteredFriends) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <Stack spacing={1}>
      <MuiTelInput
        defaultCountry="US"
        value={currNum}
        onChange={(value: string, info: MuiTelInputInfo): void => {
          setCurrNum(info.numberValue || "");
        }}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            phoneNumSubmit();
          }
        }}
        onBlur={phoneNumSubmit}
        label="Invite friends via phone number"
        sx={{ width: "100%" }}
      />
      <Autocomplete
        options={filteredFriends}
        value={textsToInvite}
        onChange={(_, value): void => {
          // @ts-ignore
          const numbers: string[] = value.filter(
            (option: string | MonkeyUser) => typeof option === "string"
          );
          // this onchange needs to support removing numbers as otherwise theyre stuck there forever
          if (numbers.length < numbersToInvite.length) {
            setNumbersToInvite(numbers);
          }
          // @ts-ignore
          const users: MonkeyUser[] = value.filter(
            (option: string | MonkeyUser) => typeof option !== "string"
          );

          setFriendsToInvite(users);
        }}
        multiple
        freeSolo
        filterSelectedOptions
        isOptionEqualToValue={(
          option: MonkeyUser | string,
          value: MonkeyUser | string
        ) => {
          let optionStr = undefined;
          let valueStr = undefined;
          if (typeof option === "string") {
            optionStr = option;
          } else {
            optionStr = option.id;
          }

          if (typeof value === "string") {
            valueStr = value;
          } else {
            valueStr = value.id;
          }

          return optionStr === valueStr;
        }}
        getOptionLabel={(option: string | MonkeyUser) => {
          if (typeof option === "string") {
            return option;
          }
          return `${option.first_name} ${option.last_name}`;
        }}
        renderOption={(
          props,
          option: MonkeyUser | string
        ): React.ReactElement => {
          const labelToDisplay =
            typeof option === "string"
              ? option
              : `${option.first_name} ${option.last_name}`;
          return (
            <li {...props}>
              <Typography component="span" variant="body2">
                {labelToDisplay}
              </Typography>
            </li>
          );
        }}
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <TextField
              {...params}
              placeholder="Or pick a friend to invite"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: theme.palette.background.default, // Set the border color you want
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.background.default, // Ensure the hover color is the same
                  },
                },
              }}
            />
          );
        }}
      />

      {/* num_add_ons_allowed */}
      <Typography component="span" variant="body2" paddingTop="8px">
        Your message to your new guests:
      </Typography>
      <MonkeyTextField
        mode="light"
        value={message}
        multiline
        onChange={(event: any) => setMessage(event.target.value)}
      />
      <Box justifyContent="flex-end" display="flex" paddingTop="8px">
        <MonkeyButton
          text="Invite"
          onClick={(): Promise<void> => createSignUpLinksWrapper()}
          disabled={!textsToInvite.length}
          tooltip="If you enter a phone number, make sure to hit 'Enter'"
        />
      </Box>
    </Stack>
  );
};
