import { Box } from "@mui/material";
import React from "react";

import { MonkeyLoading } from "../components/Loading";

export const LoadingPage = (): React.ReactElement => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      width="100%"
    >
      <MonkeyLoading />
    </Box>
  );
};
