import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import React from "react";
import { EventLocation } from "../../apiClient/data-contracts";

export const LocationAutocomplete = ({
  locationOptions,
  locationId,
  locationError,
  setLocationError,
  onChange,
  freeSolo = true,
}: {
  locationOptions: EventLocation[];
  locationId?: string;
  locationError?: string | undefined;
  setLocationError?: (arg: string | undefined) => void;
  onChange: any;
  freeSolo?: boolean;
}): React.ReactElement => {
  return (
    <Autocomplete
      options={locationOptions ?? []}
      sx={{ width: "100%" }}
      value={
        locationOptions?.find(
          (option: EventLocation) => option.id === locationId
        ) ?? null
      }
      onChange={onChange}
      freeSolo={freeSolo}
      autoSelect
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            label="Location"
            onChange={(): void => {
              setLocationError?.(undefined);
            }}
            error={!!locationError}
            helperText={locationError}
          />
        );
      }}
      getOptionLabel={(option: EventLocation | string) => {
        if (typeof option === "string") {
          return option;
        }
        return option.address;
      }}
      renderOption={(props, option) => {
        let renderVal;
        let renderKey;
        if (typeof option === "string") {
          renderVal = option;
          renderKey = option;
        } else {
          renderVal = option.address;
          renderKey = option.id;
        }
        return (
          <li {...props} key={renderKey}>
            {renderVal}
          </li>
        );
      }}
    />
  );
};
