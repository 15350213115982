import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { RoughLocationSelect } from "../../Locations/Map/components/RoughLocSelect";
import { useMapIsLoaded } from "../../Locations/Map/hooks";
import { RoughLocSchema } from "../../Locations/Map/types";
import { EventLocation } from "../../apiClient/data-contracts";
import { MonkeyButton } from "../../components/Button";
import {
  useGetOrCreateLocationMutation,
  useListPerformerSpecialtiesQuery,
  useListPerformerTypesQuery,
  useMakeAutoFlowRequestMutation,
} from "../../endpoints";
import { ExtraSpecPage } from "./ExtraSpecPage";
import { FeatureSelectPage } from "./FeaturesSelect";
import { NavToManualCreate } from "./NavToManualCreate";
import { PerformerSelect } from "./PerformerSelect";
import { SpecificLocationSelect } from "./SpecificLocSelect";
import {
  AutoEventCreateForm,
  FeatureTypes,
  autoEventCreateFormSchema,
} from "./requestTypes";
import { convertZodAutoToApiAuto } from "./utils";

export const CreateAuto = (): React.ReactElement => {
  ///////////
  // Hooks //
  ///////////
  const [makeAutoFlowRequest, { isLoading: isRequestAutoFlowCreate }] =
    useMakeAutoFlowRequestMutation();
  const [getOrCreateLocation] = useGetOrCreateLocationMutation();

  const navigate = useNavigate();

  ///////////////
  // form init //
  ///////////////

  const {
    control,
    handleSubmit,
    register,
    setFocus,
    setValue,
    setError,
    trigger,
    formState,
  } = useForm<AutoEventCreateForm>({
    resolver: zodResolver(autoEventCreateFormSchema),
    defaultValues: {
      selectedFeatures: [],
      budget: {},
      attendees: {},
      eventTime: undefined,
      description: undefined,
      coverCharge: undefined,
      location: {
        specific: undefined,
        rough: undefined,
      },
      performersOptions: undefined,
    },
  });

  // form registration
  const selectedFeaturesController = useController({
    name: "selectedFeatures",
    control,
  }).field;

  const performersController = useController({
    name: "performersOptions",
    control,
  }).field;

  const selectedFeaturesVal = selectedFeaturesController.value;
  const setSelectedFeatures = selectedFeaturesController.onChange;

  const performersVal = performersController.value;
  const setPerformers = performersController.onChange;

  const setEventTime = React.useCallback(
    (newVal: Date): void => {
      setValue("eventTime", newVal, { shouldValidate: true });
    },
    [setValue]
  );

  const setRoughLoc = React.useCallback(
    (newVal: RoughLocSchema | undefined): void => {
      setValue("location.rough", newVal);
    },
    [setValue]
  );
  //////////////////////
  // state management //
  //////////////////////

  const [currIndex, setCurrIndex] = React.useState<number>(0);

  ///////////////////
  // derived state //
  ///////////////////

  // this useMemo keeps track of what the order of pages (and their contents) should be
  // the error fields is used to ensure we can move to the next page.
  const pageList = React.useMemo(() => {
    // have to read the errors like this such that the useMemo actually gets rederived when
    // errors change
    const { errors } = formState;
    // TODO: consider a way to only rerender the individual component when errors change
    const defaultPagesToVisit = [
      {
        name: "selectedFeatures",
        element: (
          <FeatureSelectPage
            selectedFeatures={selectedFeaturesVal}
            setSelectedFeatures={setSelectedFeatures}
            setValue={setValue}
          />
        ),
        errorFields: ["seletedFeatures"],
      },
    ];

    // must include a location - either rough when you want us to provide you a venue
    if (selectedFeaturesVal.includes(FeatureTypes.Venue)) {
      defaultPagesToVisit.push({
        name: "location.rough",
        element: <RoughLocationSelect setRoughLoc={setRoughLoc} />,
        errorFields: ["location"],
      });
      // or you can just tell us the addy otherwise
    } else {
      defaultPagesToVisit.push({
        name: "location.specific",
        element: <SpecificLocationSelect register={register} errors={errors} />,
        errorFields: ["location"],
      });
    }

    if (selectedFeaturesVal.includes(FeatureTypes.Performer)) {
      defaultPagesToVisit.push({
        name: "performer",
        element: (
          <PerformerSelect
            performers={performersVal}
            setPerformers={setPerformers}
            errors={errors}
          />
        ),
        errorFields: ["performersOptions"],
      });
    }

    // then we want to essentially postpend the details page at the end

    // last page should be the extra spec page
    defaultPagesToVisit.push({
      name: "extraSpec",
      element: (
        <ExtraSpecPage
          setEventTime={setEventTime}
          errors={errors}
          register={register}
        />
      ),
      errorFields: [
        "budget",
        "budget.min",
        "budget.max",
        "attendees",
        "attendees.min",
        "attendees.max",
        "eventTime",
      ],
    });

    return defaultPagesToVisit;
  }, [
    formState,
    selectedFeaturesVal,
    setSelectedFeatures,
    setValue,
    setEventTime,
    register,
    setRoughLoc,
    performersVal,
    setPerformers,
  ]);

  ////////////////
  // Preloading //
  ////////////////

  // preload these so theyre loaded by the time they get to the performers page
  // but only if the performer select is selected
  useListPerformerSpecialtiesQuery(
    selectedFeaturesVal.includes(FeatureTypes.Performer) ? undefined : skipToken
  );
  useListPerformerTypesQuery(
    selectedFeaturesVal.includes(FeatureTypes.Performer) ? undefined : skipToken
  );

  // preload the map, TODO: Try to see if we can only do this when you select the venue feature
  // also TODO: the thing that takes time is finding the users current location
  useMapIsLoaded();

  ///////////////
  // callbacks //
  ///////////////
  const onSubmit = async (values: AutoEventCreateForm): Promise<void> => {
    // we first need to validate that the location they provided is valid
    let validatedLocation: EventLocation | undefined | void;
    // but only if they need to fill out specific location
    if (
      !selectedFeaturesVal.includes(FeatureTypes.Venue) &&
      values.location.specific
    ) {
      validatedLocation = await getOrCreateLocation(values.location.specific)
        .unwrap()
        .catch((error: any) => {
          // if an error occurs, guide them back to the location page, and set an error on the field
          setError("location", {
            message: error.message ?? "Couldn't find this location",
          });
          setFocus("location.specific");
          const specLocPage = pageList.find(
            (page) => page.name === "location.specific"
          );
          const specificLocIdx = specLocPage
            ? pageList.indexOf(specLocPage)
            : undefined;
          if (specificLocIdx === undefined) {
            console.error("Couldn't find the spec loc page");
          } else {
            setCurrIndex(specificLocIdx);
          }
        });
      if (!validatedLocation) {
        return;
      }
    }
    const requestParams = convertZodAutoToApiAuto(
      values,
      validatedLocation || undefined
    );
    makeAutoFlowRequest(requestParams)
      .unwrap()
      .then((response) => navigate(`/autoflow/view/${response}`));
  };

  const checkPagePasses = async (pageIdx: number): Promise<boolean> => {
    const errorFields = pageList[pageIdx].errorFields;
    let focusField = undefined;
    for (let i = 0; i < errorFields.length; i += 1) {
      const currErrorField = errorFields[i] as any;
      const isOk = await trigger(currErrorField as any, { shouldFocus: true });
      let focusTag = currErrorField;
      if (focusTag === "location") {
        if (!selectedFeaturesVal.includes(FeatureTypes.Venue)) {
          focusTag = "location.specific";
        }
      }
      if (["budget", "attendees"].includes(focusTag)) {
        // override it to focus on max
        focusTag = `${focusTag}.max`;
      }

      if (!isOk && !focusField) {
        focusField = focusTag;
      }
    }
    if (focusField) {
      setFocus(focusField as any);
      return false;
    }
    return true;
  };

  //////////////////
  // final return //
  //////////////////

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        {/* Landing screen */}
        {/* Non-Default page 1 and onwards */}
        {currIndex === 0 || !!selectedFeaturesVal.length ? (
          pageList[currIndex].element
        ) : (
          <NavToManualCreate setCurrIndex={setCurrIndex} />
        )}

        {/* Bottom navbar */}
        {(currIndex === 0 || !!selectedFeaturesVal.length) && (
          <Box width="100%" display="flex" justifyContent="center">
            <Stack direction="row" spacing={1}>
              {currIndex > 0 && (
                <MonkeyButton
                  text="Previous"
                  onClick={(): void => {
                    setCurrIndex((prev) => prev - 1);
                  }}
                />
              )}
              {currIndex === Object.keys(pageList).length - 1 ? (
                <MonkeyButton
                  text="Submit"
                  type="submit"
                  disabled={isRequestAutoFlowCreate}
                  onClick={async () => await checkPagePasses(currIndex)}
                />
              ) : (
                <MonkeyButton
                  text="Next"
                  onClick={async (e): Promise<void> => {
                    e.preventDefault();

                    const allChecksPass = await checkPagePasses(currIndex);
                    if (allChecksPass) {
                      setCurrIndex((prev) => prev + 1);
                    }
                  }}
                />
              )}
            </Stack>
          </Box>
        )}
      </Stack>
    </form>
  );
};
