import { useParams } from "react-router-dom";

import { Box, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { differenceInDays, format, formatRelative } from "date-fns";
import React from "react";
import {
  Event,
  EventLocation,
  Guest,
  MonkeyUser,
  Performer,
} from "../apiClient/data-contracts";
import { MonkeyButton } from "../components/Button";
import { MonkeyModal } from "../components/MonkeyModal";
import {
  useGetEventQuery,
  useGetGuestsForEventQuery,
  useGetLocationQuery,
  useGetPerformersForEventQuery,
  useGetUserInfoQuery,
  useMakeFriendsMutation,
  useUpdateGuestMutation,
} from "../endpoints";
import { useIsSmallScreen } from "../logic/hooks";
import { InviteNewGuest } from "./InviteNewGuest";
import { GuestStatusEnum } from "./types";

export const EventView = (): React.ReactElement => {
  const { eventId } = useParams();

  // make a call to get event info
  // performer info
  // location info
  // host info
  // should distinguish between whether youre a guest or the host
  const {
    data: eventData,
    isLoading: isEventLoading,
    isError,
  } = useGetEventQuery(eventId ?? skipToken);

  const { data: userInfo, isLoading: isUserLoading } = useGetUserInfoQuery();

  const { data: guestData, isLoading: isGuestDataLoading } =
    useGetGuestsForEventQuery(eventData?.id ? eventData.id : skipToken);
  const { data: performersData, isLoading: isPerformersDataLoading } =
    useGetPerformersForEventQuery(eventData?.id ? eventData.id : skipToken);
  const { data: locationData, isLoading: isLocationDataLoading } =
    useGetLocationQuery(eventData?.location ? eventData.location : skipToken);
  if (
    isEventLoading ||
    isGuestDataLoading ||
    isPerformersDataLoading ||
    isLocationDataLoading ||
    isUserLoading
  ) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }
  if (!eventData) {
    return (
      <Typography component="span" variant="body1">
        You cannot view this event's details...
      </Typography>
    );
  }
  if (!userInfo || !guestData) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }
  return (
    <EventViewBody
      userInfo={userInfo}
      eventData={eventData}
      guestData={guestData}
      performersData={performersData}
      locationData={locationData}
    />
  );
};

const GuestsSection = ({
  guestData,
  eventData,
  userInfo,
}: {
  guestData: Guest[];
  eventData: Event;
  userInfo: MonkeyUser;
}): React.ReactElement => {
  // state
  const [invGuestModalOpen, setInvGuestModalOpen] = React.useState(false);
  const [guestsFilter, setGuestsFilter] = React.useState<
    GuestStatusEnum | "ALL"
  >("ALL");

  const [updateGuest, { isLoading: isUpdatingGuest }] =
    useUpdateGuestMutation();

  const currUserIsHost =
    userInfo?.id && eventData?.hosts?.includes(userInfo.id);

  // RTK/Hooks
  const [addFriend] = useMakeFriendsMutation();
  const isSmallScreen = useIsSmallScreen();

  // Callbacks
  const filteredGuests = React.useMemo(() => {
    const guests = guestData;
    if (!guests) {
      return [];
    }
    switch (guestsFilter) {
      case "ALL":
        return guests;
      case GuestStatusEnum.NO_RESPONSE:
        return guests.filter(
          (guest) =>
            !guest.status || guest.status === GuestStatusEnum.NO_RESPONSE
        );
      default:
        return guests.filter((guest) => guest.status === guestsFilter);
    }
  }, [guestData, guestsFilter]);

  const addFriendWrapper = async (toFriendId?: string): Promise<void> => {
    if (!toFriendId) {
      return;
    }
    await addFriend(toFriendId).unwrap();
  };

  if (!eventData) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }
  const currUserId = userInfo?.id?.toString();
  if (currUserId === undefined) {
    return (
      <Typography component="span" variant="body1">
        Error...
      </Typography>
    );
  }

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center" paddingTop="16px">
        <Typography component="span" variant="body1" sx={{ fontSize: "18px" }}>
          Guests:
        </Typography>
        <TextField
          select
          sx={{
            display: "inline-block",
            ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
              paddingY: "4px",
              paddingLeft: "8px",
              paddingRight: "34px",
            },
          }}
          value={guestsFilter}
          onChange={(textFieldEvent: any): void => {
            setGuestsFilter(textFieldEvent.target.value);
          }}
          variant="outlined"
        >
          <MenuItem value="ALL">All</MenuItem>
          <MenuItem value={GuestStatusEnum.YES}>Yes</MenuItem>
          <MenuItem value={GuestStatusEnum.NO}>No</MenuItem>
          <MenuItem value={GuestStatusEnum.MAYBE}>Maybe</MenuItem>
          <MenuItem value={GuestStatusEnum.NO_RESPONSE}>No response</MenuItem>
        </TextField>
        {currUserIsHost && (
          <MonkeyButton
            text="+ Invite"
            onClick={(): void => setInvGuestModalOpen(true)}
          />
        )}
      </Stack>
      {filteredGuests.map((guest) => {
        const guestIdentifier =
          guest.first_name || guest.last_name
            ? `${guest.first_name} ${guest.last_name}`
            : guest.primary_contact?.phone_number;
        return (
          <Stack direction="row" spacing={1} alignItems="center" key={guest.id}>
            {guest.user_id === currUserId ? (
              <>
                <Typography component="span" variant="body1">
                  You -
                </Typography>
                <TextField
                  disabled={isUpdatingGuest}
                  select
                  sx={{
                    display: "inline-block",
                    ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
                      paddingY: "4px",
                      paddingLeft: "8px",
                      paddingRight: "34px",
                    },
                  }}
                  value={
                    guestData.find((guest) => guest.user_id === userInfo.id)
                      ?.status ?? GuestStatusEnum.NO_RESPONSE
                  }
                  onChange={async (textFieldEvent: any): Promise<void> => {
                    updateGuest({
                      id: guest.id,
                      status: textFieldEvent.target.value,
                    });
                  }}
                  variant="outlined"
                >
                  <MenuItem value={GuestStatusEnum.YES}>Yes</MenuItem>
                  <MenuItem value={GuestStatusEnum.NO}>No</MenuItem>
                  <MenuItem value={GuestStatusEnum.MAYBE}>Maybe</MenuItem>
                  <MenuItem value={GuestStatusEnum.NO_RESPONSE}>
                    No response
                  </MenuItem>
                </TextField>
              </>
            ) : (
              <>
                <Typography
                  component="span"
                  variant="body1"
                >{`${guestIdentifier} - ${guest.status}`}</Typography>
                {!guest.friends?.includes(currUserId) && (
                  <MonkeyButton
                    text="Add friend"
                    onClick={(): Promise<void> =>
                      addFriendWrapper(guest.user_id)
                    }
                  />
                )}
              </>
            )}
          </Stack>
        );
      })}
      <MonkeyModal
        open={invGuestModalOpen}
        onClose={(): void => {
          setInvGuestModalOpen(false);
        }}
        title="Invite guest?"
        width={isSmallScreen ? "75%" : undefined}
      >
        <InviteNewGuest
          eventData={eventData}
          currGuests={guestData ?? []}
          onClose={(): void => {
            setInvGuestModalOpen(false);
          }}
        />
      </MonkeyModal>
    </>
  );
};

const EventViewBody = ({
  eventData,
  guestData,
  performersData,
  locationData,
  userInfo,
}: {
  eventData: Event;
  guestData: Guest[];
  performersData?: Performer[];
  locationData?: EventLocation;
  userInfo: MonkeyUser;
}): React.ReactElement => {
  let displayDate = undefined;
  try {
    const partyDate = new Date(eventData.start);
    const now = new Date();
    if (differenceInDays(partyDate, now) > 5) {
      const timeToDisplay = format(new Date(eventData.start), "haa");
      const dateToDisplay = format(new Date(eventData.start), "MMMM do");
      displayDate = `${timeToDisplay.toLowerCase()} on ${dateToDisplay}`;
      displayDate = format(new Date(eventData.start), "haa 'on' MMMM do");
    } else {
      displayDate = formatRelative(new Date(eventData.start), new Date());
    }
  } catch (e) {
    console.error(e);
  }
  return (
    <Stack spacing={1} alignItems="center">
      <Box paddingBottom="30px">
        <Typography component="h2" variant="h2">
          {eventData?.name}
        </Typography>
      </Box>
      {eventData?.host_names?.length && (
        <Typography component="span" variant="body1">
          {`Hosted by: ${eventData?.host_names.join(", ")}`}
        </Typography>
      )}
      {displayDate && (
        <Typography component="span" variant="body1">
          Starts: {displayDate}
        </Typography>
      )}
      {locationData?.address && (
        <Typography component="span" variant="body1">
          Location: {locationData?.address}
        </Typography>
      )}
      {!!performersData?.length && (
        <Typography component="span" variant="body1">
          Performers: {performersData?.map((performer) => performer.name)}
        </Typography>
      )}
      {!!eventData?.description && (
        <Typography component="span" variant="body1" sx={{ paddingTop: "8px" }}>
          {eventData.description}
        </Typography>
      )}
      <GuestsSection
        guestData={guestData}
        eventData={eventData}
        userInfo={userInfo}
      />
    </Stack>
  );
};
