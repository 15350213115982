import { Divider, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { EventLocation } from "../apiClient/data-contracts";
import { useListLocationsQuery } from "../endpoints";
import { getDisplayForMinMaxObj } from "../helpers/utils";

const LocationCard = ({
  location,
}: {
  location: EventLocation;
}): React.ReactElement => {
  const navigate = useNavigate();

  const actualCard = (
    <Stack
      spacing={1}
      border="1px solid lightgrey"
      width="100%"
      padding="16px"
      borderRadius="4px"
      alignItems="center"
    >
      <Typography component="span" variant="body1">
        {`Location: ${location.address}`}
      </Typography>
      {!!location.website && (
        <Typography component="span" variant="body1">
          <Link to={location.website} style={{ color: "#FFFFFF" }}>
            Website
          </Link>
        </Typography>
      )}
      {!!location.capacity && (
        <Typography component="span" variant="body1">
          {`Capacity: ${getDisplayForMinMaxObj({
            minMaxObj: location.capacity,
          })}`}
        </Typography>
      )}
      {!!location.price_range && (
        <Typography component="span" variant="body1">
          {`Price Range: ${getDisplayForMinMaxObj({
            minMaxObj: location.price_range,
            prefix: "$",
          })}`}
        </Typography>
      )}
    </Stack>
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      divider={
        <Divider
          orientation="vertical"
          sx={{ color: "lightgrey", height: "100%" }}
        />
      }
      sx={{ ":hover": { cursor: "pointer" } }}
      onClick={(): void => {
        navigate(`/locations/view/${location.id}`);
      }}
    >
      {actualCard}
    </Stack>
  );
};

const BrowseFilters = ({
  generalFilter,
  setGeneralFilter,
}: {
  generalFilter: string;
  setGeneralFilter: (value: string) => void;
}): React.ReactElement => {
  return (
    <Stack spacing={1} width="100%">
      <TextField
        value={generalFilter}
        placeholder="Filter"
        onChange={(event: any): void => {
          setGeneralFilter(event.target.value || "");
        }}
      />
    </Stack>
  );
};

export const LocationBrowse = (): React.ReactElement => {
  // state
  const [generalFilter, setGeneralFilter] = React.useState<string>("");

  // RTK
  const { data: locationOptions, isLoading: isLocationsLoading } =
    useListLocationsQuery();

  if (isLocationsLoading) {
    return (
      <Typography component="span" variant="body1">
        Loading...
      </Typography>
    );
  }

  return (
    <Stack spacing={2} alignItems="center">
      <Typography component="h2" variant="h3">
        Locations
      </Typography>
      <BrowseFilters
        generalFilter={generalFilter}
        setGeneralFilter={setGeneralFilter}
      />
      <Stack
        height="70vh"
        overflow="auto"
        spacing={1}
        justifyContent="flex-start"
        width="350px"
      >
        {locationOptions
          ?.filter((location) => !!location.id)
          .filter(
            (location) =>
              location.address.includes(generalFilter) ||
              location.clean_address?.includes(generalFilter)
          )
          .map((location) => {
            return <LocationCard key={location.id} location={location} />;
          })}
      </Stack>
    </Stack>
  );
};
