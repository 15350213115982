/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GetGuestViaLinkRequest, Guest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Guests<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get info about a guest using a link_id
   *
   * @tags guests
   * @name GetGuestViaLink
   * @request POST:/guests/get_guest_via_link/
   * @secure
   */
  getGuestViaLink = (data: GetGuestViaLinkRequest, params: RequestParams = {}) =>
    this.request<Guest, any>({
      path: `/guests/get_guest_via_link/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags guests
   * @name GuestsRead
   * @request GET:/guests/{id}/
   * @secure
   */
  guestsRead = (id: string, params: RequestParams = {}) =>
    this.request<Guest, any>({
      path: `/guests/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags guests
   * @name GuestsUpdate
   * @request PUT:/guests/{id}/
   * @secure
   */
  guestsUpdate = (id: string, data: Guest, params: RequestParams = {}) =>
    this.request<Guest, any>({
      path: `/guests/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags guests
   * @name GuestsPartialUpdate
   * @request PATCH:/guests/{id}/
   * @secure
   */
  guestsPartialUpdate = (id: string, data: Guest, params: RequestParams = {}) =>
    this.request<Guest, any>({
      path: `/guests/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
}
