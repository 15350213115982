/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SendPasswordResetOtpRequest,
  SetNewPasswordWithToken,
  Success,
  UserLoginRequest,
  UserRegisterRequest,
  UserRegisterResponse,
  VerifyOtpPasswordTokenRequest,
  VerifyOtpPasswordTokenResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class UsersUnauthed<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Whether someone is logged in currently
   *
   * @tags users-unauthed
   * @name IsLoggedIn
   * @request GET:/users-unauthed/is_logged_in/
   * @secure
   */
  isLoggedIn = (params: RequestParams = {}) =>
    this.request<Success, any>({
      path: `/users-unauthed/is_logged_in/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Login a user
   *
   * @tags users-unauthed
   * @name UserLogin
   * @request POST:/users-unauthed/login_user/
   * @secure
   */
  userLogin = (data: UserLoginRequest, params: RequestParams = {}) =>
    this.request<Success, any>({
      path: `/users-unauthed/login_user/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Logout a user
   *
   * @tags users-unauthed
   * @name UserLogout
   * @request GET:/users-unauthed/logout_user/
   * @secure
   */
  userLogout = (params: RequestParams = {}) =>
    this.request<Success, any>({
      path: `/users-unauthed/logout_user/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Register a new user
   *
   * @tags users-unauthed
   * @name RegisterUser
   * @request POST:/users-unauthed/register_user/
   * @secure
   */
  registerUser = (data: UserRegisterRequest, params: RequestParams = {}) =>
    this.request<UserRegisterResponse, any>({
      path: `/users-unauthed/register_user/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Forgot password, send otp
   *
   * @tags users-unauthed
   * @name SendPasswordResetOtp
   * @request POST:/users-unauthed/send_password_reset_otp/
   * @secure
   */
  sendPasswordResetOtp = (data: SendPasswordResetOtpRequest, params: RequestParams = {}) =>
    this.request<Success, any>({
      path: `/users-unauthed/send_password_reset_otp/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Verify otp password for reset
   *
   * @tags users-unauthed
   * @name UpdatePasswordWithToken
   * @request POST:/users-unauthed/update_password_with_token/
   * @secure
   */
  updatePasswordWithToken = (data: SetNewPasswordWithToken, params: RequestParams = {}) =>
    this.request<Success, any>({
      path: `/users-unauthed/update_password_with_token/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Verify otp password for reset
   *
   * @tags users-unauthed
   * @name VerifyOtpPasswordReset
   * @request POST:/users-unauthed/verify_otp_password_reset/
   * @secure
   */
  verifyOtpPasswordReset = (data: VerifyOtpPasswordTokenRequest, params: RequestParams = {}) =>
    this.request<VerifyOtpPasswordTokenResponse, any>({
      path: `/users-unauthed/verify_otp_password_reset/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
