/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AutoFlowCreationRequest } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class AutoFlowRequests<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags auto-flow-requests
   * @name AutoFlowRequestsList
   * @request GET:/auto-flow-requests/
   * @secure
   */
  autoFlowRequestsList = (params: RequestParams = {}) =>
    this.request<AutoFlowCreationRequest[], any>({
      path: `/auto-flow-requests/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags auto-flow-requests
   * @name AutoFlowRequestsRead
   * @request GET:/auto-flow-requests/{id}/
   * @secure
   */
  autoFlowRequestsRead = (id: string, params: RequestParams = {}) =>
    this.request<AutoFlowCreationRequest, any>({
      path: `/auto-flow-requests/${id}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags auto-flow-requests
   * @name AutoFlowRequestsUpdate
   * @request PUT:/auto-flow-requests/{id}/
   * @secure
   */
  autoFlowRequestsUpdate = (id: string, data: AutoFlowCreationRequest, params: RequestParams = {}) =>
    this.request<AutoFlowCreationRequest, any>({
      path: `/auto-flow-requests/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description A viewset for viewing and editing user instances.
   *
   * @tags auto-flow-requests
   * @name AutoFlowRequestsPartialUpdate
   * @request PATCH:/auto-flow-requests/{id}/
   * @secure
   */
  autoFlowRequestsPartialUpdate = (id: string, data: AutoFlowCreationRequest, params: RequestParams = {}) =>
    this.request<AutoFlowCreationRequest, any>({
      path: `/auto-flow-requests/${id}/`,
      method: "PATCH",
      body: data,
      secure: true,
      format: "json",
      ...params,
    });
}
