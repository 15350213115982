import { Box, Modal, Stack, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import theme, { lightTheme } from "../theme";

interface MonkeyModalProps {
  open: boolean;
  onClose: () => void;
  width?: string;
  title?: string;
  children: React.ReactElement;
}
export const MonkeyModal: React.FC<MonkeyModalProps> = ({
  children,
  open,
  onClose,
  width = "40%",
  title,
}: MonkeyModalProps): React.ReactElement => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <ThemeProvider theme={lightTheme}>
        <Stack
          width={width}
          padding="16px"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "4px",
          }}
          minHeight="200px"
        >
          {title && (
            <Box
              width="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
              paddingBottom="16px"
            >
              <Typography
                component="h3"
                variant="h3"
                color={theme.palette.background.default}
              >
                {title}
              </Typography>
            </Box>
          )}
          {children}
        </Stack>
      </ThemeProvider>
    </Modal>
  );
};
